import { isOneOf } from '@helpers/core/typeGuards';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { JobPosition, JobPositionDetail } from '@routes/jobPosition';
import { SortItem } from '@shared/unsorted/Sort/Sort';
import { SelectionOption } from '@typedefs/selectOption';
import { useEffect, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { InitialState } from './init';
import { useListPageTrackerContext } from '../../../contexts/ListPageTrackerContext';

interface JobPositionsPageHookType {
    currentPage: number;
    changePage: (currentPage: number) => void;
    rowsPerPage: number;
    rowsPerPageOptions: SelectionOption[];
    changeRowsPerPage: (rowsPerPage: string) => void;
    getPageCount: (totalRows: number) => number;
    openJobPositionPage: (id: string) => void;
    sortOptions: SortItem[];
    currentSortId: string;
    onSort: (sortId: string) => void;
    getRowIndexes: (currentPage: number, rowsPerPage: number, totalRows: number) => { startRowIndex: number, endRowIndex: number };
    searchKeyword?: string | null;
    onSubmitSearch: (keyword: string) => void;
}

const useJobPositionsPage = (initialState: InitialState): JobPositionsPageHookType => {
    const navigate = useNavigate()
    const queryParams = JobPosition.useSearchParams()

    const [, setLastestListPageRoute] = useListPageTrackerContext()

    const location = useLocation()

    const { error: toastError } = useToast()

    const sortOptions: SortItem[] = [
        {
            label: "jobPositions.list.sort.addedDesc",
            id: "CREATED_AT desc",
            value: {
                field: "CREATED_AT",
                direction: "desc"
            }
        },
        {
            label: "jobPositions.list.sort.addedAsc",
            id: "CREATED_AT asc",
            value: {
                field: "CREATED_AT",
                direction: "asc"
            }
        },
        {
            label: "jobPositions.list.sort.latestUpdate",
            id: "UPDATED_AT desc",
            value: {
                field: "UPDATED_AT",
                direction: "desc"
            }
        },
        {
            label: "jobPositions.list.sort.titleDesc",
            id: "TITLE desc",
            value: {
                field: "TITLE",
                direction: "desc"
            }
        },
        {
            label: "jobPositions.list.sort.titleAsc",
            id: "TITLE asc",
            value: {
                field: "TITLE",
                direction: "asc"
            }
        }
    ]

    const rowsPerPageOptions: SelectionOption[] = [
        {
            label: "10",
            value: "10",
            key: "10"
        },
        {
            label: "25",
            value: "25",
            key: "25"
        },
        {
            label: "50",
            value: "50",
            key: "50"
        },
        {
            label: "75",
            value: "75",
            key: "75"
        },
        {
            label: "100",
            value: "100",
            key: "100"
        },
    ]

    const currentSortId = useMemo(() => `${queryParams.sortType} ${queryParams.order}`, [queryParams.sortType, queryParams.order])

    const changePage = (currentPage: number) => {
        navigate(JobPosition.toRoute({
            ...queryParams,
            page: currentPage,
        }))
    }

    const changeRowsPerPage = (rowsPerPage: string) => {
        navigate(JobPosition.toRoute({
            ...queryParams,
            page: 1,
            rowsPerPage: parseInt(rowsPerPage),
        }))
    }

    const openJobPositionPage = (id: string) => {
        const path = generatePath(JobPositionDetail.PATH_NAME, { id });

        navigate(path);
    };

    const onSort = (sortId: string) => {
        const sortItem = sortOptions.find(sortItem => sortItem.id === sortId) || sortOptions[0]

        if (isOneOf(JobPosition.supportedSort)(sortItem.value.field)) {
            navigate(JobPosition.toRoute({
                ...queryParams,
                sortType: sortItem.value.field,
                order: sortItem.value.direction,
                // mode: 'list'
            }))
        }
    }

    const getRowIndexes = (currentPage: number, rowsPerPage: number, totalRows: number) => {
        const endRowIndex = rowsPerPage * currentPage >= totalRows ? totalRows : rowsPerPage * currentPage
        const startRowIndex = rowsPerPage * (currentPage - 1) + 1 >= endRowIndex ? endRowIndex : rowsPerPage * (currentPage - 1) + 1

        return { startRowIndex, endRowIndex }
    }

    const onSubmitSearch = (keyword: string) => {
        navigate(JobPosition.toRoute({
            ...queryParams,
            page: 1,
            search: keyword,
        }));
    };

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])

    useEffect(() => {
        setLastestListPageRoute({
            PATH_NAME: location.pathname,
            search: location.search
        })
    }, [location])

    return {
        currentPage: queryParams.page,
        rowsPerPage: queryParams.rowsPerPage,
        changePage,
        rowsPerPageOptions,
        changeRowsPerPage,
        getPageCount: (totalRows: number) => Math.ceil(totalRows / queryParams.rowsPerPage),
        openJobPositionPage,
        sortOptions,
        currentSortId,
        onSort,
        getRowIndexes,
        searchKeyword: queryParams.search,
        onSubmitSearch,
    }
}

export {
    useJobPositionsPage
};
