{
  "translation": {
    "analytics": {
      "title": "リポート"
    },
    "applications": {
      "activeJobs": "アクティブな求人",
      "allJobs": "すべての求人",
      "bulkAction": {
        "updateStepStatus": "ステータスを更新する",
        "assignToSession": "セッションに割り当てる...",
        "exportToCSV": "選択した候補者をCSVファイルとしてダウンロードする",
        "archive": "選考中の候補者をアーカイブする",
        "revertToActive": "アーカイブされた候補者を選考中に戻す",
        "noArchivedCandidateSelection": "アーカイブされた候補が選択されていないため無効です",
        "noActiveCandidateSelection": "選考中の候補者が選択されていないため無効です",
        "noCandidateInSameJobAndStep": "このオプションを有効にするには、同じ求人の選考ステップに属する候補者を選択する必要があります",
        "ASSIGN_TO_SESSION_MANUALLY": {
          "title": "複数候補者をまとめてセッショングループへ追加"
        },
        "ARCHIVE": {
          "title": "アーカイブ",
          "description": "{{count}}の候補者を“{{archiveReason}}”としてアーカイブしますか？",
          "cancel": "キャンセル",
          "primaryCTA": "アーカイブ"
        },
        "COMPLETE_EVALUATION": {
          "title": "評価の確定",
          "description": "{{count}}名の候補者の評価を確定しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "確定"
        },
        "CONFIRM_DOCUMENTS": {
          "title": "書類の承認",
          "description": "{{count}}名の候補者の書類を承認しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "承認"
        },
        "FINALIZE_FAIL": {
          "title": "不合格（最終確定）登録",
          "description": "{{count}}名の候補者を「不合格（最終確定）」として登録しますか？",
          "note": "ご確認ください：「合格（暫定）」の候補者が含まれています。このまま登録すると全ての候補者が「不合格（最終確定）」として登録されます。このまま登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "FINALIZE_PASS": {
          "title": "合格（最終確定）登録",
          "description": "{{count}}名の候補者を「合格（最終確定）」として登録しますか？",
          "note": "ご確認ください：「不合格（暫定）」の候補者が含まれています。このまま登録すると全ての候補者が「合格（最終確定）」として登録されます。このまま登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "MARK_AS_FAIL": {
          "title": "不合格（暫定）登録",
          "description": "{{count}}名の候補者を「不合格（暫定）」として登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "MARK_AS_JOINED": {
          "title": "入社済み登録",
          "description": "{{count}}名の候補者を「入社済み」として登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "MARK_AS_PASS": {
          "title": "合格（暫定）登録",
          "description": "{{count}}名の候補者を「合格（暫定）」として登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "MARK_AS_UNDECIDED": {
          "title": "未決定（暫定）登録",
          "description": "{{count}}名の候補者を「未決定（暫定）」として登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "REGISTER_ATTENDANCE": {
          "title": "Register Attendance",
          "description": "{{count}}名の候補者をイベントに参加済みとして登録しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "登録"
        },
        "REVERT_TO_PREVIOUS_STATUS": {
          "title": "選考中に戻す",
          "description": "{{count}}名の候補者を「選考中」に戻しますか？",
          "note": "ご確認ください：注：「戻す」を選択した場合、各候補者の選考プロセスが再開され、アーカイブする前の状態に戻ります",
          "cancel": "キャンセル",
          "primaryCTA": "戻す"
        },
        "SEND_DOCUMENT_NOTIFICATION": {
          "title": "書類通知の送信",
          "description": "{{count}}名の候補者に書類通知メールを送信しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "送信"
        },
        "SEND_EVENT_ANNOUNCEMENT": {
          "title": "イベント告知の送信",
          "description": "{{count}}名の候補者にイベント告知メールを送信しますか？",
          "cancel": "キャンセル",
          "primaryCTA": "送信"
        },
        "success": "一括処理が正常に完了しました"
      },
      "bulkActionError": {
        "subDescription": {
          "allError": "{{action}}の実行に失敗しました。再試行するか、別のバッチアクションを選択できます。",
          "partialError": "一部の候補者の一括処理に失敗しました。もう一度お試しください。"
        },
        "result": {
          "success": "正常に処理が完了しました",
          "failed": "処理に失敗しました",
          "description": "{{count}}名の候補者："
        },
        "note": null
      },
      "bulkActionLoading": {
        "processCandidates": "{{total}}名中、{{processed}}名の候補者を処理中です"
      },
      "bulkEventSessionAssignment": {
        "sessionSelection": {
          "title": "セッションの選択",
          "jobTitle": "求人名:",
          "currentRecruitmentStep": "現在の選考ステップ:",
          "eventName": "イベント名:",
          "selectSession": "セッションを選択してください。",
          "numOfGroup": "{{count}} グループ",
          "chooseGroup": "次へ：グループの選択"
        },
        "sessionGroupSelection": {
          "title": "グループの選択",
          "selectedSession": "選択されたセッション",
          "selectGroup": "グループを選択してください。",
          "examiners": "選考官:",
          "moreExaminers": "他{{count}}名",
          "candidates": "参加候補者数:",
          "candidateCount": "{{registered}}/{{total}} 名",
          "backToSessionSelection": "セッションの選択に戻る",
          "confirm": "グループ割当を確認する",
          "exceedCapacity": "選択した候補者をグループに追加すると、イベント作成時に設定された１グループあたりの候補者定員を超過します。"
        }
      },
      "bulkEventSessionAssignmentOption": {
        "title": "選択された候補者のうち{{count}}名は、すでに他のセッショングループに割り当て済みです。",
        "description": "どのように進めますか？",
        "proceedAll": "選択した候補者全員をこのセッショングループに割り当てる",
        "proceedUnassigned": "未指定の候補者のみを処理する ({{count}})"
      },
      "bulkEventSessionAssignmentError": {
        "description": "セッショングループに複数の候補者の追加に失敗しました",
        "subDescription": "再試行するか、別の一括処理を選択してください。"
      },
      "csvImport": {
        "backToCandidateTop": "候補者ホームに戻る",
        "switchToManualInput": "手動入力に切り替える",
        "title": "CSVファイルでインポート",
        "steps": {
          "setup": "設定",
          "errorCorrection": "エラーの修正",
          "preview": "プレビュー",
          "import": "インポート"
        },
        "setupStep": {
          "sectionHeader": {
            "importSettings": "インポート設定",
            "uploadFile": "ファイルのアップロード"
          },
          "templateName": "候補者登録フォーマット.csv",
          "uploadFileRequirement": "Bluumhireフォーマットに従ってファイルをアップロードすることを推奨します（必須ではありません）。<highlight>テンプレートのダウンロードはこちら</highlight>"
        },
        "errorCorrectionStep": {
          "errorDescription": "アップロードされたファイルに無効な項目が見つかりました。エラー項目CSVをダウンロードして修正してください。",
          "successDescription": "エラー項目が修正されました。インポート処理を続行してください。",
          "downloadErrorFile": "エラー項目CSVをダウンロード",
          "sectionHeader": {
            "reuploadFile": "ファイルの再アップロード"
          },
          "reuploadFileRequirement": "エラーCSVファイルをダウンロードし、無効な項目を修正した後、CSV形式で再アップロードして、インポートするすべての項目のプレビューを続行します。",
          "errorFileName": "取り込みエラー.csv",
          "errors": {
            "invalidValue": "<highlight>{{count}} 名</highlight> の入力内容に誤りがあります",
            "invalidFormat": "<highlight>{{count}} 名</highlight> の入力形式が正しくありません",
            "missingValue": "<highlight>{{count}} 名</highlight> に空欄があります"
          }
        },
        "uploadArea": {
          "clickToUpload": "クリックしてアップロード",
          "dragDrop": "、またはドラッグ＆ドロップ",
          "fileNote": "ファイルは CSV 形式で {{size}}未満である必要があります。"
        },
        "previewStep": {
          "sectionHeader": {
            "importSettings": "インポート設定",
            "tablePreview": "テーブルプレビュー"
          },
          "jobPosition": "求人:",
          "acquisitionChannel": "情報取得経路:",
          "entryChannel": "エントリー経路:",
          "recruitmentStep": "選考ステップ:",
          "changeImportSettings": "インポート設定の変更",
          "totalCount": "インポートする候補者の総数 <highlight>{{count}}</highlight>",
          "tableHeaders": {
            "rowNumber": "データ行",
            "candidateName": "候補者",
            "university": "学校名"
          },
          "rowsPerPage": "ページあたりの行数",
          "backToSetup": "設定に戻る",
          "backToErrorCorrection": "エラーの修正に戻る",
          "startImport": "インポートを開始"
        },
        "importStep": {
          "inProgress": {
            "importingFile": "ファイルをインポートしています",
            "progressText": "{{current}}/{{total}}名の候補者を処理中"
          },
          "success": {
            "importSuccess": "ファイルのインポートに成功しました",
            "description": "{{count}}人の候補者が追加されました。",
            "viewCandidateList": "候補者一覧を見る",
            "importAnotherFile": "別のファイルをインポートする"
          },
          "failure": {
            "importFailed": "ファイルのインポートに失敗しました",
            "description": "インポート中にエラーが発生しました。再処理を行うか、時間をおいて再度インポートを実行してください。\n問題が解決しない場合は開発チームまでお問い合わせください。",
            "retry": "再処理する",
            "contactUs": "お問い合わせをする"
          }
        },
        "moveToPreview": "プレビューに進む"
      },
      "create": {
        "manualInput": {
          "title": "手入力で個別追加",
          "switchToCSVImport": "CSVファイルインポートに切り替える",
          "backToCandidateTop": "候補者ホームに戻る",
          "label": {
            "dateOfBirth": "生年月日",
            "email": "PCメールアドレス",
            "entryStatus": "エントリー状況",
            "expectedGraduationMonth": "卒業予定月",
            "expectedGraduationYear": "卒業予定年",
            "firstName": "名",
            "firstNameKana": "名（ふりがな）",
            "gender": "性別",
            "graduationMonth": "卒業月",
            "graduationYear": "卒業年",
            "lastName": "姓",
            "lastNameKana": "姓（ふりがな）",
            "name": "名前",
            "nameKana": "名前(カナ)",
            "phoneNumber": "携帯電話番号"
          }
        },
        "button": "候補者の追加",
        "buttons": {
          "add": "追加"
        },
        "entryStatus": {
          "afterEntry": "エントリー済み",
          "beforeEntry": "未エントリー (情報取得のみ)"
        },
        "label": {
          "entryCategory": "エントリー経路カテゴリ",
          "entryChannel": "エントリー経路",
          "entryChannelDisabledTooltip": null,
          "jobPosition": "求人",
          "jobPositionOptionDisabledTooltip": null,
          "jobPositionUnconfiguredStepsTooltip": "該当する求人が選択不可能な場合は、求人ページでイベントの設定や評価フォームの追加をし、求人内容を確定してください。求人内容が確定されるとその求人が選択可能になります。",
          "informationCategory": "情報取得カテゴリ",
          "informationChannel": "情報取得経路",
          "informationChannelDisabledTooltip": null,
          "recruitmentStep": "選考ステップ",
          "recruitmentStepDisabledTooltip": null
        },
        "jobPositionExplanation": {
          "shortText": "未完成の求人は選択できません。<highlight>詳細はこちら</highlight>",
          "title": "希望する求人を選択できないのはなぜですか？",
          "description": {
            "main": "お探しの求人が選択できない場合は、「求人」タブで設定を完了する必要があります：",
            "step1": "1. すべての「イベント」タイプのステップにイベントを設定する",
            "step2": "2. メールテンプレート（必要な場合）がすべてのステップに適切に割り当てられている。"
          }
        },
        "modes": {
          "csv": "BluumHire指定のCSVでインポート",
          "manual": "手入力で個別追加",
          "medium": "各媒体で出力したファイルでインポート"
        },
        "modal": {
          "description": "候補者の追加方法を選択してください",
          "options": {
            "csv": {
              "label": "CSVファイルでインポート (推奨）",
              "description": "BluumhireテンプレートCSVファイルを使用して複数の候補者を追加する"
            },
            "manual": {
              "label": "手入力で個別追加",
              "description": "候補者を1人ずつ手動で追加する"
            }
          },
          "title": "候補者の追加"
        },
        "sectionHeader": {
          "applicationInformation": "応募情報",
          "basicInformation": "基本情報",
          "candidateInformation": "候補者情報"
        },
        "title": "候補者の追加"
      },
      "defaultSteps": {
        "all": "すべて",
        "entry": "エントリー",
        "offer": "内定",
        "offerAccepted": "内定承諾",
        "ongoing": "選考中"
      },
      "deleteDetailModal": {
        "buttons": {
          "delete": "削除"
        },
        "evaluation": {
          "confirmation": "評価情報を削除しますか？",
          "description": "{{recruitmentStepName}}の評価情報を削除します。削除した評価情報は元に戻せません。",
          "error": "評価情報の削除に失敗しました。",
          "success": "評価情報の削除が完了しました。",
          "title": "評価情報を削除しますか？"
        },
        "screeningDetail": {
          "confirmation": "選考情報を削除しますか？",
          "description": "{{recruitmentStepName}}の選考情報を削除します。削除した選考情報は元に戻せません。",
          "error": "選考情報の削除に失敗しました。",
          "success": "選考情報の削除が完了しました。",
          "title": " 選考情報を削除しますか？"
        }
      },
      "errors": {
        "appliedCandidateEmail": "この候補者はすでに他の求人に紐付けられています",
        "invalidCandidateData": "候補者情報の更新が出来ませんでした。入力内容をご確認ください。"
      },
      "exportCandidates": {
        "button": "候補者CSVダウンロード",
        "filename": "Bluum_{{action}}候補者一覧_{{date}}.csv",
        "actions": {
          "all": "全ての候補者をダウンロード",
          "active": "選考中候補者のみをダウンロード",
          "archive": "過去の選考候補者のみをダウンロード"
        }
      },
      "filters": {
        "candidateStatus": "候補者ステータス",
        "candidateStatusOptions": {
          "ONGOING": "選考中",
          "ARCHIVED": "過去の選考"
        },
        "clear": "クリア",
        "unavailableReason": {
          "unavailableOnArchived": "選考中の候補者が含まれるように \"候補者ステータス\" をご調整ください。"
        },
        "emptySearch": "検索条件に一致するエントリーはありません。別のキーワードで検索してください。",
        "jobTitle": "求人名",
        "recruitmentStage": "選考ステージ",
        "recruitmentStepStatus": "選考ステップステータス",
        "recruitmentStepStatusGroups": {
          "GENERAL": "一般",
          "DOCUMENT": "書類選考",
          "EVENT": "イベント",
          "ARCHIVE_REASON": "過去の選考"
        },
        "searchPlaceholder": "検索する",
        "stepName": "ステップ名",
        "stepNameDisabledTooltip": "「ステップ名」を選択する際は「求人名」を一つ選択してください",
        "university": "学校名"
      },
      "list": {
        "all": "すべて",
        "candidatesCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> 人 <highlight>{{count}}</highlight> 人中",
        "displayCount": "人",
        "emptyNoJob": {
          "description": "候補者の追加には求人作成が必要です",
          "subDescription": "求人が存在しないため候補者を追加することが出来ません。\n求人一覧ページより求人の作成をおこなってください。"
        },
        "emptyPastApplication": {
          "description": "アーカイブされた候補者はいません",
          "subDescription": "現在、アーカイブされた候補者はいません。"
        },
        "emptyUnderSelection": {
          "description": "選考中候補者はいません",
          "subDescription": "現在、選考中候補者はいません。"
        },
        "emptyAll": {
          "description": "まだ候補者がいません",
          "subDescription": "候補者を追加して選考の管理をはじめましょう。"
        },
        "errors": {
          "appliedCandidateEmail": "この候補者はすでに他の求人に紐付けられています",
          "invalidCandidateData": "候補者情報の更新が出来ませんでした。入力内容をご確認ください。"
        },
        "filterButton": "絞り込み",
        "filterButtonCounter_other": "{{count}}件適用中",
        "filterButtonCounter_zero": "",
        "mismatchedUnderSelection": {
          "description": "検索結果なし",
          "subDescription": "検索条件に一致する求人がありません。別のキーワードをお試しください。"
        },
        "orderBy": "並べ替え",
        "people": "人",
        "searchPlaceholder": "候補者名で検索",
        "selected": "{{count}}が選択された",
        "sendAnnouncement": "選考案内を送信",
        "sort": {
          "candidateNameAsc": "候補者名（昇順）",
          "candidateNameDesc": "候補者名（降順）",
          "createdAtAsc": "追加日（昇順）",
          "createdAtDesc": "追加日（降順）",
          "updatedAtAsc": "更新日（昇順）",
          "updatedAtDesc": "最終更新日"
        },
        "subtitle": "追加された候補者の確認、新規候補者の追加、選考の管理などができます。",
        "table": {
          "active": "選考中",
          "allApplicationsSelected": "{{count}}人の候補者全員が選出",
          "applicationSelected": "{{count}}名の候補者が選出",
          "archiveActions": "アーカイブ",
          "archived": "過去の選考",
          "bulkActions": "一括処理",
          "bulkActionsDisabledTooltip": null,
          "recruitmentState": "選考状況",
          "currentStep": "現在のステップ",
          "deselectAllApplication": "すべて選択解除",
          "deselectApplicationOnThisPage": "このページで{{count}}人の候補者の選択を解除する",
          "entryChannel": "応募経路",
          "jobTitle": "求人名",
          "name": "候補者名",
          "noBulkAction": null,
          "recruitmentStage": "選考ステージ",
          "selectAllCandidates": "すべての候補者を選択({{count}}名)",
          "stepStatus": "ステータス",
          "university": "学校名"
        },
        "title": "候補者リスト",
        "totalCount": "人中"
      },
      "newCandidate": "候補者の追加",
      "panel": {
        "addTag": "タグを追加",
        "candidateApplicationInformation": {
          "acquisitionCategory": "情報取得カテゴリ",
          "acquisitionChannel": "情報取得経路",
          "afterEntry": "エントリー済み",
          "beforeEntry": "未エントリー (情報取得のみ)",
          "edit": {
            "label": {
              "entryCategory": "エントリー経路カテゴリ",
              "entryChannel": "エントリー経路"
            },
            "placeholder": {
              "entryCategory": "",
              "entryChannel": ""
            }
          },
          "entryCategory": "エントリーカテゴリ",
          "entryChannel": "エントリー経路",
          "entryDate": "応募日時",
          "entryStatus": "エントリー状況",
          "title": "応募情報"
        },
        "candidateApplicationInformationDisplay": {
          "entryChannel": "エントリー",
          "informationAcquisition": "情報取得"
        },
        "candidateBasicInformation": {
          "currentAddress": "現住所",
          "dateOfBirth": "生年月日",
          "edit": {
            "currentAddress": "現住所 詳細",
            "errors": {
              "update": "候補者情報を更新しました。"
            },
            "phoneNumber": "現住所 電話番号",
            "placeholder": {
              "currentAddress": "市区町村名 番地・ビル名",
              "email": "例）hello.bluum@email.com",
              "furigana": "例：タナカ タロウ",
              "name": "例：田中 太郎",
              "phoneNumber": "Ex) 09012349876",
              "zipCode": "Ex) 09012345678"
            },
            "prefecture": "現住所 都道府県",
            "residenceCountry": "居住国",
            "successes": {
              "update": "候補者情報を更新しました。"
            },
            "zipCode": "現住所 郵便番号"
          },
          "email": "PCメール",
          "furigana": "名前 (カタカナ)",
          "gender": "性別",
          "mobilePhoneNumber": "携帯電話番号",
          "name": "名前",
          "overseas": "海外",
          "phoneNumber": "現住所電話番号",
          "title": "候補者基本情報"
        },
        "candidateDetailPanel": {
          "lastUpdate": "最終更新: ",
          "navigation": {
            "applicationInformation": "応募情報",
            "candidateInformation": "候補者基本情報",
            "contact": "その他連絡先",
            "jobHistory": "職歴",
            "other": "その他情報",
            "schoolInformation": "学校情報"
          },
          "title": "候補者詳細プロフィール"
        },
        "candidateDocuments": {
          "download": "ダウンロード",
          "downloadAll": "すべてをダウンロード",
          "empty": {
            "description": "書類はまだありません",
            "subDescription": "[編集]をクリックして書類をアップロードする事ができます。"
          },
          "title": "添付書類",
          "uploadSelectButton": "ファイルを選択",
          "uploadSelectFileInstruction": "対応ファイル：jpeg, jpg, png, bmp, pdf, xlsx, docx, pptx",
          "uploadSelectSizeInstruction": "対応サイズ：{{size}}まで",
          "uploadSelectTitle": "ファイルを選択してアップロード"
        },
        "candidateEmail": {
          "addCcBcc": "Cc/Bccを追加",
          "bcc": "Bcc",
          "cc": "Cc",
          "content": "本文",
          "createEmail": "メールの作成",
          "date": "日付",
          "emptyDescription": "まだメールは受信していません",
          "emptySubDescription": "この申請に関するメールの通知は利用可能になり次第、こちらに表示されます。",
          "errors": {
            "sendEmail": "メールが送信できませんでした。"
          },
          "from": "送信者",
          "received": "受信：",
          "reply": "返信する",
          "sendEmail": "メールを送信",
          "sent": "送信：",
          "subject": "件名",
          "successes": {
            "sendEmail": "メールを送信しました"
          },
          "title": "メールを送信",
          "to": "受信者"
        },
        "candidateHistory": {
          "decision": {
            "final": {
              "FAILED": "不合格",
              "PASSED": "合格"
            },
            "preliminary": {
              "FAILED": "不合格（暫定）",
              "PASSED": "合格（暫定）",
              "UNDECIDED": "未確定"
            }
          },
          "documentConfirmed": "確認済み",
          "labels": {
            "currentStatus": "ステータス",
            "decision": "合否判定",
            "documentStatus": "書類の状況",
            "evaluation": "評価",
            "examiners": "選考官",
            "schedule": "スケジュール"
          },
          "pending": "保留中",
          "viewEvaluations": "評価を確認する"
        },
        "candidateInformationUpdated": "候補者情報の更新が完了しました。",
        "candidateJobHistoryInformation": {
          "departmentAndPositionTitle": "部署・役職",
          "edit": {
            "errors": {
              "update": "候補者情報を更新しました。"
            },
            "placeholder": {
              "departmentAndPositionTitle": "部署・役職",
              "lastJobPosition": "会社名"
            },
            "successes": {
              "update": "候補者情報を更新しました。"
            }
          },
          "lastJobPosition": "最終勤務先",
          "title": "職歴"
        },
        "candidateOtherContactInformation": {
          "edit": {
            "errors": {
              "update": "候補者情報を更新しました。"
            },
            "placeholder": {
              "mobileEmail": "Ex) hello.bluum@mobile.com",
              "vacationAddress": "市区町村名 番地・ビル名",
              "vacationPhoneNumber": "Ex) 0312345678",
              "vacationZipcode": "Ex) 1234567"
            },
            "successes": {
              "update": "候補者情報を更新しました。"
            },
            "vacationAddress": "休暇中住所",
            "vacationPrefecture": "休暇中住所 都道府県",
            "vacationZipcode": "休暇中住所 郵便番号"
          },
          "mobileEmail": "携帯メール",
          "title": "その他連絡先",
          "vacationContactAddress": "休暇中住所",
          "vacationPhoneNumber": "休暇中電話番号"
        },
        "candidateOtherInformation": {
          "edit": {
            "errors": {
              "update": "候補者情報を更新しました。"
            },
            "successes": {
              "update": "候補者情報を更新しました。"
            }
          },
          "remarks": "備考",
          "title": "その他情報"
        },
        "candidateSchoolInformation": {
          "clubActivity": "クラブ・サークル",
          "edit": {
            "errors": {
              "update": "候補者情報を更新しました。"
            },
            "expectedGraduationMonth": "卒業予定月",
            "expectedGraduationYear": "卒業予定年",
            "graduationMonth": "卒業月",
            "graduationYear": "卒業年",
            "placeholder": {
              "graduationYear": "Ex) 2022"
            },
            "schoolType": "学校種別",
            "successes": {
              "update": "候補者情報を更新しました。"
            }
          },
          "expectedGraduation": "卒業予定年月",
          "fieldType": "文理区分",
          "graduationYearMonth": "{{year}}年{{month}}月",
          "major": "専攻",
          "researchTheme": "専攻・研究テーマ",
          "school": "学校",
          "seminarLab": "ゼミ・研究室",
          "title": "学校情報"
        },
        "currentStep": "現在のステップ: ",
        "editTags": {
          "error": "タグの保存に失敗しました。",
          "success": "タグを保存しました。"
        },
        "email": "メール",
        "entryChannel": "エントリー経路: ",
        "evaluateApplication": {
          "backToEvaluation": "戻る",
          "cancel": "破棄する",
          "cancellationDescription": "破棄すると評価内容は失われます。\n\nこの評価内容を破棄しますか？",
          "cancellationTitle": "評価内容の破棄",
          "error": "評価を保存できませんでした",
          "saveDraft": "下書きを保存",
          "submissionDescription": "評価を提出した後は、変更を加えることはできません。\n\nこのまま評価を提出しますか？",
          "submissionTitle": "評価の提出",
          "submit": "評価を提出する",
          "success": "評価は正常に提出されました",
          "title": ""
        },
        "fillEvaluation": {
          "error": "登録された評価フォームがありません"
        },
        "finalizeDecision": {
          "description": "不合格の理由を選択してください。",
          "error": "決定を確定できませんでした",
          "success": "決定を確定しました",
          "title": "最終決定",
          "warning": "この候補者は{{preliminaryResult}}として登録されています。{{finalResult}}として最終確定しますか？"
        },
        "graduationYearMonth": "{{year}}年{{month}}月 卒業予定",
        "moreInfo": "詳細",
        "note": {
          "add": "メモを追加",
          "error": "メモを保存出来ませんでした。",
          "success": "メモを保存しました。",
          "title": "メモ"
        },
        "recruitmentStepArchiveActions": "アーカイブ",
        "recruitmentStepFinalizeDecision": "合否を確定する",
        "recruitmentStepMainActions": "合否を仮設定する",
        "recruitmentStepOtherActions": "その他アクション",
        "revertApplication": {
          "buttons": {
            "confirm": "選考中に戻す"
          },
          "description": "この応募者の選考プロセスを再開する場合は「選考中に戻す」ボタンを選択してください。アーカイブされる前の状態に戻ります。",
          "error": "選考中に戻すことが出来ませんでした。",
          "success": "応募を選考中に戻しました。",
          "title": "選考を再開する"
        },
        "revertToActive": "選考中に戻す",
        "saveTag": "保存",
        "scheduleEvent": "日程を設定する",
        "tabs": {
          "candidateInfo": "候補者情報",
          "document": "書類",
          "email": "メール交流",
          "history": "履歴"
        },
        "updated": "更新日: ",
        "viewApplicationEvaluations": {
          "noEvaluation": "入力された評価はありません",
          "respondents": "評価者",
          "title": "評価"
        }
      },
      "pastApplication": {
        "all": "全て",
        "closedOther": "その他の理由",
        "declinedContinueCurrentJob": "辞退 (現職継続)",
        "declinedJoinedOtherCompany": "辞退 (他社内定)",
        "declinedOthers": "辞退 (その他)",
        "emptyTable": "選考が終了した応募がありません。",
        "joined": "その他の理由",
        "noReply": "返信なし・面接欠席",
        "rejectedCultureMismatched": "不合格 (カルチャー不一致)",
        "rejectedOthers": "不合格 (その他)",
        "rejectedSkillMismatched": "不合格 (スキル不一致)"
      },
      "subMenu": {
        "active": "選考中候補者",
        "archived": "過去の選考候補者",
        "jobPositionFilter": {
          "buttonLabel": "すべて解除"
        },
        "noArchivedApplications": "過去の応募はありません。",
        "noActiveApplication": "選考中の応募はありません。",
        "activeApplication": "選考中の応募"
      }
    },
    "calendar": {
      "title": "カレンダー"
    },
    "comingSoon": {
      "contactUs": "問い合わせ",
      "description": "{{feature}}機能がまもなく登場！",
      "subDescription": "アプリは現在開発中です。気になる方はお気軽にお問い合わせください。"
    },
    "csvExportErrorModal": {
      "description": "候補一覧のエクスポートに失敗しました",
      "subDescription": "エクスポート ファイルの準備中にエラーが発生しました。後でもう一度試してください。この問題が引き続き発生する場合は、開発チームに報告してください。"
    },
    "csvExportingModal": {
      "actions": {
        "active": "選考中",
        "archived": "過去の選考"
      },
      "description": "{{action}}候補者一覧エクスポート",
      "exportedItems": "{{count}}項目",
      "subDescription": "候補者一覧のエクスポートには時間がかかる場合があります。 CSV ファイルの準備中はページを閉じたり更新したりしないでください。"
    },
    "dashboard": {
      "comingSoon": "近日公開",
      "recentActivity": "最近のアクティビティ",
      "stats": {
        "noChange": "変更なし",
        "trendDataLabel": "先月比",
        "trendDataValue": "({{value}}%)",
        "viewDetails": "詳細を見る"
      },
      "thisWeek": "今週",
      "title": "ダッシュボード",
      "upcomingEvents": {
        "noEvent": "予定されているイベントはありません",
        "title": "今後のイベント",
        "participant": "{{count}}名の参加者、"
      },
      "activities": {
        "noActivity": "アクティビティ履歴はありません"
      }
    },
    "devLogin": {
      "email": "メール",
      "password": "パスワード"
    },
    "documentUpload": {
      "instruction": "必要な書類をこちらにアップロードしてください。",
      "title": "応募者書類"
    },
    "emails": {
      "templates": {
        "variables": {
          "descriptions": {
            "candidate_name": null,
            "company_name": null,
            "document_upload_deadline": null,
            "document_upload_url": null,
            "evaluation_deadline": null,
            "evaluation_url": null,
            "event_contact": null,
            "event_date_time": null,
            "event_examiner_location": null,
            "event_location": null,
            "event_page_url": null,
            "examiner_name": null,
            "external_signature": null,
            "internal_signature": null,
            "job_name": null,
            "reservation_deadline": null,
            "reservation_url": null,
            "step_name": null
          },
          "names": {
            "candidate_name": "候補者名",
            "company_name": "企業名",
            "document_upload_deadline": "書類締切",
            "document_upload_url": "書類選考リンク",
            "evaluation_deadline": "評価シート締切",
            "evaluation_url": "評価シートリンク",
            "event_contact": "緊急連絡先",
            "event_date_time": "日時",
            "event_examiner_location": "選考官場所",
            "event_location": "場所",
            "event_page_url": "イベントURL",
            "examiner_name": "選考官",
            "external_signature": "署名",
            "internal_signature": "Internal_署名",
            "job_name": "求人名",
            "reservation_deadline": "予約締切",
            "reservation_url": "予約リンク",
            "step_name": "ステップ名"
          },
          "samples": {
            "candidate_name": "山田涼",
            "company_name": "株式会社Bluum",
            "document_upload_deadline": "7月05日 23:00",
            "document_upload_url": "https://bluum.jp/document-upload?application_id=abcd1234-abcd-1234-abcd-1234abcd1234&step_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "evaluation_deadline": "7月17日 23:00",
            "evaluation_url": "https://forms.gle/AbCd1234aBcD1234A",
            "event_contact": "030-1234-5678",
            "event_date_time": "7月12日 13:00-14:00",
            "event_examiner_location": "会議室A",
            "event_location": "東京都渋谷区恵比寿１−２３−２３",
            "event_page_url": "https://bluum.jp/job-positions?mode=show&id=abcd1234-abcd-1234-abcd-1234abcd1234&event_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "examiner_name": "佐藤蓮",
            "external_signature": "株式会社Bluum 新卒採用担当\nrecruit@bluum.com",
            "internal_signature": "採用チーム @recruitment",
            "job_name": "総合職（理系）",
            "reservation_deadline": "7月05日 23:00",
            "reservation_url": "https://bluum.jp/event_reservation?email_identifier=abcd1234-abcd-1234-abcd-1234abcd1234&application_id=abcd1234-abcd-1234-abcd-1234abcd1234",
            "step_name": "グループディスカッション"
          }
        }
      }
    },
    "event": {
      "format": {
        "offline": "対面",
        "online": "オンライン"
      },
      "addSession": {
        "candidateCapacity": "1グループごと候補者定員",
        "candidateCapacityUnit": "名",
        "createFailed": "日程を追加できませんでした",
        "createSuccessfully": "イベント日程が登録されました",
        "duration": "実施時間",
        "durationUnit": "分",
        "endTime": "終了時間",
        "endTimeTooltip": "終了時間はイベント登録時に設定した「実施時間」を元に自動で入力されます。",
        "examinersLabel": "選考官",
        "format": "実施方法",
        "groupCount": "グループ数",
        "groupCountTooltip": "同日・同時刻で複数のイベントを実施したい場合、グループ数を2以上に変更してください。",
        "groupLabel": "{{count}}つ目のグループ",
        "locationOfflineLabel": "イベント開催場所",
        "locationOnlineLabel": "イベントURL",
        "placeholders": {
          "locationOffline": "開催場所を入力する",
          "locationOnline": "URLを入力する"
        },
        "startDate": "日程",
        "startTime": "開始時間",
        "title": "日程を追加"
      },
      "addSessionGroup": {
        "createFailed": "イベント日程グループの作成に失敗しました",
        "createSuccessfully": "イベント日程グループが正常に作成されました",
        "title": "イベント日程の追加"
      },
      "eventDeleteConfirmationModal": {
        "deleteEvent": "イベントの削除",
        "deleteEventFailed": "イベントの削除に失敗しました",
        "deleteEventSuccessfully": "イベントは正常に削除されました",
        "description": "イベントの削除",
        "subDescription": "イベント「{{eventName}}」を削除してもよろしいですか? このイベントを削除すると元に戻すことはできません。"
      },
      "eventDetail": {
        "addSession": "日程を追加",
        "displayNameLabel": "候補者向けイベント名",
        "empty": {
          "description": "登録されている日程がありません。",
          "subDescription": "候補者をイベントに割り当てるには日程を追加してください。"
        },
        "eventSessionManagement": "日程管理",
        "eventSettings": "イベント設定",
        "formatLabel": "実施方法",
        "hideCompletedSessions": "実施済みイベントを非表示にする",
        "maxCapacity": "{{count}}名",
        "maxCapacityLabel": "候補者向けイベント名",
        "reservationDeadline": "{{days}}日後{{time}}:00まで",
        "reservationDeadlineLabel": "予約変更・キャンセル期限",
        "table": {
          "additionTypes": {
            "auto": "自動抽出",
            "manual": "手動"
          },
          "availabilityStatuses": {
            "completed": "実施済",
            "ongoing": "実施中",
            "upcoming": "実施予定"
          },
          "header": {
            "additionType": "追加方法種別",
            "eventSession": "イベント日程",
            "groupsCount": "グループ数",
            "reservedCandidatesCount": "予約候補者数",
            "status": "ステータス"
          },
          "reservedCandidates": "{{registered}} / {{total}} 名"
        }
      },
      "eventSession": {
        "eventSessionGroup": {
          "name": "{{count}}つ目のグループ"
        },
        "addGroup": "イベント日程の追加",
        "candidateCapacity": "{{count}}名",
        "candidateCapacityLabel": "1グループごと候補者定員",
        "groupCount": "{{count}}グループ",
        "groupCountLabel": "現在のグループ数",
        "sessionGroup": "イベント日程グループ一覧",
        "table": {
          "applicantsCount": "{{registered}} / {{total}}名",
          "copyToClipboard": {
            "copiedTooltip": "コピーしました",
            "copyTooltip": "リンクをコピー"
          },
          "extraMenu": {
            "delete": "イベント日程グループの削除",
            "editCandidates": "候補者の操作",
            "editExaminers": "選考官の操作",
            "editLocation": "開催場所の編集",
            "tooltip": {
              "candidateRegisteredTooltipText": "本グループは候補者もしくは候補者が決定しているため、削除できません。",
              "editTooltipText": "実施済みイベント日程のグループは編集できません。",
              "lastGroupTooltip": "グループの削除に失敗しました。イベント日程のグループを全て削除する場合は、【日程の削除】をしてください。"
            }
          },
          "header": {
            "action": "アクション",
            "applicants": "予約候補者 (確認済み {{count}})",
            "applicantsTooltip": "応募者は日程回答と同時に参加が確定します。",
            "examiner": "選考官",
            "location": "開催場所",
            "sessionUrl": "開催URL"
          },
          "notAssigned": "未設定"
        }
      },
      "eventSessionGroupAddCandidateModal": {
        "appliedJobPositionLabel": "応募求人",
        "candidateToAdd": "追加する候補者:",
        "candidateToAddPlaceholder": "追加する候補者を選択してください",
        "currentRecruitmentStep": "現在の選考ステップ",
        "description": "以下の条件に当てはまる候補者を選択して、イベント日程グループに手動で候補者を追加することが出来ます。",
        "emailDelivery": "2. イベント予約メールの送付設定",
        "error": "候補者の追加に失敗しました。",
        "selectCandidate": "1. 候補者の選択",
        "sendEventReservation": "追加する候補者へのイベント予約メールを送信する",
        "sendEventReservationTooltip": "「送信する」にチェックを入れると、追加と同時に選択した候補者に対して通常のイベント予約時に候補者に送信されるメールを送信することができます。",
        "success": "候補者を追加しました。",
        "title": "イベント日程グループへの候補者の追加",
        "warning": "選択した候補者をグループに追加すると、イベント作成時に設定された１グループあたりの候補者定員を超過します。"
      },
      "eventSessionGroupDeleteModal": {
        "description": "このイベント日程グループを削除しますか？",
        "error": "グループの削除に失敗しました。",
        "subDescription": "削除したグループは元に戻せません。グループを削除しますか？",
        "success": "グループを削除しました。"
      },
      "eventSessionGroupManipulateCandidatesModal": {
        "action": "アクション",
        "addCandidate": "候補者の追加",
        "allocatedCandidate": "割り当て候補者",
        "candidateCount": "{{registered}}/{{total}} 名",
        "examiners": "選考官",
        "location": "選考官向け開催場所",
        "noAllocatedCandidates": "現在割り当てられた候補者はいません",
        "reservedCandidates": "予約候補者",
        "title": "予約候補者のグループ間入れ替え"
      },
      "eventSessionGroupManipulateExaminersModal": {
        "error": "面接官の変更に失敗しました。",
        "examinersLabel": "選考官",
        "success": "面接官が変更されました。",
        "title": "選考官のグループ間入れ替え"
      },
      "eventSessionGroupManipulateLocationModal": {
        "errors": {
          "update": "場所の変更に失敗しました。"
        },
        "successes": {
          "update": "場所が変更されました。"
        },
        "title": "開催場所のグループ間入れ替え"
      },
      "eventSessionGroupRemoveCandidateModal": {
        "cancellationEmail": "削除する候補者への予約キャンセルメールを送信する",
        "cancellationEmailTooltip": null,
        "description": "この候補者をグループから削除しますか？",
        "failedToRemove": "グループの削除に失敗しました。",
        "subDescription": "予約候補者「{{candidateName}}」をこのグループから削除します。 削除は取り消しすることができません。",
        "successfullyRemove": "グループを削除しました。"
      },
      "eventSessionUniqueLocationWarningModal": {
        "buttons": {
          "back": "戻る",
          "continue": "変更を保存"
        },
        "groupError": " - <highlight>{{path}}</highlight>と同じ",
        "sameUrlBetweenEventDescription1": "以下のグループは、他のセッションと同じセッションURLを持っています。",
        "sameUrlBetweenEventDescription2": "日程の追加を続行しますか?",
        "sameUrlBetweenEventTitle": "セッションURLの重複があります",
        "sameUrlBetweenGroupDescription1": "次のグループには同じセッション URL があります。",
        "sameUrlBetweenGroupDescription2": "日程の追加を続行しますか?",
        "sameUrlBetweenGroupTitle": "同じセッション URL を持つグループがあります"
      },
      "setting": {
        "candidateCapacity": "候補者定員 (名)",
        "createFailedMessage": "新しいイベントの作成に失敗しました",
        "createSuccessMessage": "新しいイベントが作成されました",
        "deadline": "予約変更・キャンセル期限",
        "deadlineDaysBefore": "日前",
        "description": "登録フォーム表示項目",
        "descriptionNote": "候補者向けイベント予約ページに表示する内容を入力して下さい。\nここで入力した内容は予約ページの上部に表示されます。開催場所や連絡先情報などを入力して下さい。",
        "displayName": "候補者表示用イベント名",
        "duration": "実施時間 (分)",
        "eventFormat": "開催方法",
        "eventName": "イベント名",
        "expirationLinkDay_one": "1日後",
        "expirationLinkDay_other": "{{ count }}日後",
        "expirationLinkLabel": "イベント予約リンク有効期限",
        "expirationLinkNote": "メール送信後のイベント予約リンクの有効期間を選択してください。",
        "formatNote": "オンライン開催の場合、イベント登録後URLの設定が必須となります。",
        "placeholder": {
          "description": "イベント詳細を入力する",
          "displayName": "候補者表示用イベント名を入力する",
          "eventName": "イベント名を入力する"
        },
        "reservationDay_one": "1日後",
        "reservationDay_other": "{{ count }}日後",
        "reservationDay_zero": "今日",
        "reservationNote": "候補者がイベント予約ページにアクセスした日から起算して、いつからいつまでのイベント日程を予約可能日程として表示するかを設定します。",
        "setExpirationLink": {
          "no": "いいえ",
          "yes": "はい"
        },
        "shouldSetExpirationLink": "イベント予約リンクに有効期限を設定する",
        "shouldSetExpirationLinkNote": "「はい」を選んで、候補者にイベント予約ページのリンクを含むメールを送信してから起算した予約フォームの有効期限を設定できます。候補者が有効期限を超過した予約ページを開いた場合、予約不可の旨が表示されます。",
        "title": "イベントの登録",
        "to": "から",
        "updateFailedMessage": "イベントの更新に失敗しました",
        "updateSuccessMessage": "イベントが更新されました"
      }
    },
    "eventRegistration": {
      "confirmation": "下記ボタンを押すと、選択した日程で予約確定となります。予約後のキャンセル・日程の変更は1日前23:59まで可能です。それ以降は、採用担当者へ直接お問い合わせください。",
      "contactRecruiter": "採用担当者に連絡する",
      "durationLabel": "所要時間",
      "durationUnit": "分",
      "errors": {
        "alreadyReserved": "既にこのイベントへの予約を完了しています。",
        "reservationLinkExpired": "予約リンクが失効しています。",
        "sessionFull": "他の予約枠をお選びください。",
        "sessionNoLongerAvailable": "この予約枠は満席です。"
      },
      "instruction": "参加可能な日程を1つ選びご予約下さい。",
      "invalidLinkDescription": "予約用URLが有効ではありません。お手数をおかけしますが、採用担当者へ報告し、新規の予約用URLを入手してください。",
      "invalidLinkTitle": "予約用のURLが無効です",
      "online": "[オンライン]",
      "reserve": "選択した日程で予約する",
      "success": "予約が完了しました。",
      "successDescription": "イベントの予約が完了しました。お会いできることを楽しみにしています！",
      "successTitle": "予約が完了しました",
      "title": "日程回答のお願い"
    },
    "fieldTypes": {
      "HUMANITIES": "文系",
      "SCIENCE": "理系"
    },
    "form": {
      "errors": {
        "conflictingDate": "この日付と開始時間にはすでにセッションが存在します。日付、開始時間、またはその両方を変更してください。",
        "cutoffTimeRequired": "何時まで予約の変更・キャンセルを許可するか入力してください",
        "daysPriorRequired": "何日前まで予約の変更・キャンセルを許可するかを入力してください",
        "duplicateSessionUrl": "同じ開催場所は使用出来ません。",
        "eventNameRequired": "イベント名を入力してください",
        "expectedNewPassword": "新しいパスワードを入力してください",
        "expirationLinkRequired": "イベント予約リンク有効期限を洗濯してください。",
        "inputMismatch": "{{targetFieldName}}と入力が一致しません",
        "invalidFormat": "{{fieldName}}の形式が正しくありません",
        "reservationFromRequired": "開始日を選択してください",
        "reservationToRequired": "終了日を選択してください",
        "required": "{{fieldName}}を入力してください。",
        "reusedSessionUrl": "他の日程で使用されている選考URLです。参加対象でない候補者からのアクセスを避けたい場合、URLの変更を推奨します。",
        "stepNameRequired": "ステップ名を設定してください",
        "unknown": "この{{fieldName}}は使用できません。",
        "wrongDate": "正しい日程を選択してください",
        "wrongDateTime": "正しい日時を選択してください。",
        "wrongPassword": "パスワードが正しくありません。"
      },
      "labels": {
        "emptySelect": "選択してください",
        "emptySelectShort": "選択"
      }
    },
    "gender": {
      "FEMALE": "女性",
      "MALE": "男性",
      "NOT_APPLICABLE": "その他",
      "UNSPECIFIED": "回答しない"
    },
    "global": {
      "add": "追加",
      "apply": "適用",
      "cancel": "キャンセル",
      "close": "閉じる",
      "confirm": "確定",
      "delete": "削除",
      "dismiss": "閉じる",
      "documentUpload": {
        "fail": "アップロードに失敗しました",
        "invalidCsvFormat": "無効なファイル形式です。CSVファイルをアップロードしてください。",
        "success": "アップロードに成功しました",
        "tooLarge": "ファイルサイズが大きすぎます。{{size}}以下にしてください。"
      },
      "edit": "編集",
      "error": "エラーが発生しました",
      "login": "ログイン",
      "preview": "プレビュー",
      "remove": "削除",
      "retry": "再処理する",
      "save": "保存",
      "searchPlaceholder": "入力して エンターキーを押して検索します",
      "submit": "完了",
      "you": "個人"
    },
    "jobPositionCreate": {
      "breadcrumbs": {
        "title": "求人の作成"
      },
      "label": {
        "flow": "選考フロー",
        "flowDescription": "この求人の採用プロセスを作成する",
        "overview": "概要",
        "overviewDescription": "職種に関する一般情報",
        "requiredDocs": "必須書類",
        "title": "求人名"
      },
      "messages": {
        "errors": {
          "titleAlreadyUsed": "同じ名前の求人が既に存在します。別の名前を入力してください。"
        }
      },
      "placeholder": {
        "jobPositionName": "求人名を入力する"
      }
    },
    "jobPositions": {
      "detail": {
        "addEmail": "メールの追加",
        "addForm": "評価項目の設定",
        "empty": {
          "backToPreviousPage": "前の画面に戻る",
          "description": "求人がありません。",
          "subDescription": "申し訳ありませんが、お探しのページは存在しないか、削除されました。",
          "title": "求人詳細"
        },
        "flowDescription": "この求人の採用プロセスを表示し、ステップに必要なメール、フォーム、イベントを設定します。",
        "jobsList": "求人一覧",
        "noRequiredDocs": "必須書類なし",
        "setEvent": "イベントを設定する",
        "viewEmail": "登録内容の確認",
        "viewEmails": "合否メールの確認",
        "viewEventDetails": "イベント設定の確認",
        "viewFailDecisionEmail": "不合格メールを見る",
        "viewForm": "評価項目の確認",
        "viewPassDecisionEmail": "合格決定メールを見る"
      },
      "emailTemplateUpdateModal": {
        "buttons": {
          "exitPreview": "プレビューを閉じる"
        },
        "failDecisionTitle": "不合格通知メール",
        "notificationTitle": "案内メール",
        "passDecisionTitle": "合格通知メール",
        "previewWarning": "送信されるメールのイメージです。実際のメールでは太文字箇所の内容が候補者ごとに適宜自動で変換されます。",
        "subject": "件名"
      },
      "evaluationFormUpdateModal": {
        "addForm": "評価シートのURLを追加する",
        "disableEditTooltip": "評価者が既に評価を開始しているため、質問を変更することはできません",
        "error": "評価シートの保存に失敗しました。",
        "questionsDescription": "この採用ステップの評価項目を設定してください。入力された内容は評価フォームに反映されます。\n\n評価項目はいくつでも設定できますが、各項目は少なくとも1行空けて入力してください。空行が挿入された場合は、異なる2つの評価項目としてそれぞれ表示されます。\n\nなお、評価項目が複数行に渡る場合は、空行を入れずに入力してください。空行がない限り、ひとつの評価項目として認識され、表示されます。",
        "questionList": "質問リスト",
        "questionListPreview": "質問リスト",
        "questionsPlaceholder": "例：\n\n候補者は面接時間に遅れることなく参加しましたか？\n\n自信をもったプレゼンテーションができていましたか？\n\n候補者のコミュニケーション能力はいかがでしたか？\n10点満点で評価してください。",
        "questions": "質問",
        "success": "評価シートが保存されました",
        "title": "評価シート"
      },
      "list": {
        "createButton": "求人の作成",
        "empty": {
          "description": "まだ求人がありません",
          "moveTo": "求人一覧ページに移動",
          "subDescription": "求人を作成して採用管理をはじめましょう。"
        },
        "emptySearch": {
          "description": "検索結果なし",
          "subDescription": "検索条件に一致するエントリがありません。別のキーワードをお試しください。"
        },
        "jobPositionsCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> 求人中 <highlight>{{count}}</highlight> 職種",
        "orderBy": "並べ替え",
        "rowsPerPage": "ページあたりの行数",
        "sort": {
          "addedAsc": "追加日 (昇順)",
          "addedDesc": "追加日 (降順)",
          "latestUpdate": "最終更新日",
          "titleAsc": "求人名 (昇順)",
          "titleDesc": "求人名 (降順)"
        },
        "subtitle": "作成した求人の確認、新規求人の作成などができます。",
        "table": {
          "publicId": "求人ID",
          "status": "ステータス",
          "title": "求人名",
          "updatedAt": "最終更新"
        },
        "title": "求人一覧"
      },
      "modify": {
        "continueEditing": "編集を続ける",
        "discardChanges": "変更を破棄する",
        "jobPositionCreatedFailed": "新しい求人の保存に失敗しました",
        "jobPositionCreatedSuccessfully": "新しい求人が正常に保存されました",
        "jobPositionUpdated": "求人名を変更しました。",
        "proceed": "終了",
        "recruitmentFlowDescription": "この求人の採用プロセスを作成する",
        "saveAndExit": "保存して終了する",
        "stepNameExists": "既に存在する名前は利用できません。",
        "unsafeExitCreatedDescription": "求人はまだ保存されていません",
        "unsafeExitCreatedSubDescription": "保存せずに終了すると、加えられた変更はすべて破棄されます。終了してもよろしいですか？",
        "unsafeExitUpdatedDescription": "変更が保存されていません",
        "unsafeExitUpdatedSubDescription": "保存せずに終了すると、加えられた変更はすべて破棄されます。終了してもよろしいですか？"
      },
      "panel": {
        "add": "ステップを追加する",
        "edit": "編集",
        "incompleteStageWarning": "未完了ステージあり",
        "incompleteStageWarningTooltip": "選考を始めるためには、すべてのステージの設定を完了してください。",
        "jobTitle": "求人名",
        "jobTitleUpdated": "求人名を変更しました。",
        "mandatoryStepTooltipText": "候補者の最終合否結果を記録するステップのため、削除することはできません",
        "messages": {
          "errors": {
            "stepNameExists": "既に存在する名前は利用できません。"
          },
          "successes": {
            "jobPositionUpdated": "求人名を変更しました。",
            "recruitmentStepsUpdated": "選考ステップを更新しました。"
          }
        },
        "overview": {
          "title": "概要",
          "description": "職種に関する一般情報"
        },
        "recruitmentFlow": {
          "event": {
            "date": "日程 : ",
            "disableDeleteActionTooltip": "既に実施済みの日程がある、または応募者の参加が決定している日程がある場合イベントを削除することは出来ません。",
            "disableEditActionTooltip": "既に実施済みの日程がある、または応募者の参加が決定している日程がある場合イベントを編集することは出来ません。",
            "examiner": "選考官 : ",
            "header": "イベント",
            "notRegistered": "未登録",
            "register": "登録",
            "registered": "登録済み"
          },
          "stage": {
            "ARCHIVE": {
              "name": "アーカイブ"
            },
            "OFFER": {
              "description": "「内定」が決定し、「内定承諾前」の候補者を管理するステージです。",
              "name": "内定"
            },
            "PRE_EMPLOYMENT": {
              "description": "内定承諾後の「内定者」の状況を管理するステージです。面談や入社前研修などを登録できます。",
              "name": "入社前"
            },
            "SCREENING": {
              "description": "１次選考から最終選考までを設定します",
              "name": "選考中"
            },
            "SOURCING": {
              "description": "情報取得済みで、選考開始前の段階を指します。",
              "name": "情報取得"
            }
          },
          "stepName": "選考ステップ名",
          "steps": {
            "archived": "重複応募 ",
            "declined": "辞退",
            "eventWithoutScreening": "選考なしイベント",
            "eventWithScreening": "選考会",
            "failed": "不合格"
          },
          "stepsEdit": "選考フロー",
          "stepType": "選考ステップ種別",
          "stepTypeV2": {
            "document": "書類選考",
            "event": "イベント"
          },
          "table": {
            "addEmail": "メールの追加",
            "addEmails": "メールの追加",
            "addForm": "評価項目の設定",
            "decision": {
              "name": "合否判定",
              "tooltipText": "該当ステップの合格者、不合格者に送信する合格・不合格メールを登録できます。"
            },
            "evaluation": {
              "name": "評価の依頼",
              "tooltipText": "面接官・評価官に依頼する評価フォームを登録できます。"
            },
            "incompleteStepsWarning": "設定未完了",
            "mandatoryStepTooltipText": "「選考結果」は必須です。最終的な「入社」、「入社辞退」を記録するステップです。",
            "noSteps": "まだステップがありません",
            "noStepsWarning": "設定未完了",
            "notification": {
              "name": "選考案内",
              "tooltipText": "該当ステップに進んだ候補者に送る選考案内メールを設定することができます"
            },
            "setEvent": "イベントを設定する",
            "stepName": {
              "name": "ステップ名"
            },
            "stepType": {
              "name": "ステップタイプ"
            },
            "viewEmail": "登録内容の確認",
            "viewEmails": "合否連絡の確認",
            "viewEventDetails": "イベント設定の確認",
            "viewFailDecisionEmail": "不合格連絡",
            "viewForm": "評価項目の確認",
            "viewPassDecisionEmail": "合格連絡"
          }
        },
        "recruitmentStepsUpdated": "選考ステップを更新しました。",
        "updatedAt": "最終更新: "
      },
      "shared": {
        "documentRequirement": {
          "description": "この求人に応募する際に必要な書類は何ですか？",
          "title": "必須書類"
        },
        "draftStatus": {
          "draft": null,
          "published": null
        },
        "metadata": {
          "createdAt": "追加日",
          "createdBy": "作成者",
          "lastUpdated": "最終更新"
        },
        "placeholder": {
          "documentRequirement": "アップロードする書類に関して候補者に伝えたい関連情報を入力します。たとえば、ドキュメントのリスト、ファイルの種類、ファイル サイズ、期限などです。"
        },
        "recruitmentFlow": {
          "addAStep": "ステップを追加する",
          "decision": "合否判定",
          "decisionTooltip": "該当ステップの合格者、不合格者に送信する合格・不合格メールを登録できます。",
          "evaluation": "評価の依頼",
          "evaluationTooltip": "面接官・評価官に依頼する評価フォームを登録できます。",
          "incompleteStepsTooltip": "次のステップでイベント/メール/フォームが設定されていません：",
          "incompleteStepsWarning": "{{ numOfSteps }}つ未設定ステップあり",
          "mandatoryStepTooltipText": "「選考結果」は必須です。最終的な「入社」、「入社辞退」を記録するステップです。",
          "noStepsToShow": "まだステップがありません",
          "noStepsWarning": "設定未完了",
          "notification": "選考案内",
          "notificationTooltip": "該当ステップに進んだ候補者に送る選考案内メールを設定することができます。",
          "stepName": "ステップ名",
          "stepType": "種別",
          "title": "選考フロー",
          "disabledStepTypeTooltip": null
        },
        "stage": {
          "ARCHIVE": {
            "name": "アーカイブ"
          },
          "OFFER": {
            "description": "「内定」が決定し、「内定承諾前」の候補者を管理するステージです。",
            "name": "内定"
          },
          "PRE_EMPLOYMENT": {
            "description": "内定承諾後の「内定者」の状況を管理するステージです。面談や入社前研修などを登録できます。",
            "name": "入社前"
          },
          "SCREENING": {
            "description": "１次選考から最終選考までを設定します",
            "name": "選考中"
          },
          "SOURCING": {
            "description": "情報取得済みで、選考開始前の段階を指します。",
            "name": "情報取得"
          }
        }
      }
    },
    "livesIn": {
      "JAPAN": "国内",
      "OVERSEAS": "海外"
    },
    "loading": "読み込み中...",
    "menu": {
      "items": {
        "jobPositions": "求人管理"
      }
    },
    "notFound": {
      "backToTopPage": "トップページに戻る",
      "description": "お探しのページが見つかりませんでした",
      "subDescription": "指定されたページは削除されたか、他の場所に移動した可能性があります。"
    },
    "recruitmentStage": {
      "SOURCING": "情報取得",
      "SCREENING": "選考中",
      "OFFER": "内定",
      "PRE_EMPLOYMENT": "入社前",
      "ARCHIVE": "アーカイブ"
    },
    "recruitmentStepStatus": {
      "CLOSED_NO_REPLY": "返信無し・面接欠席",
      "CLOSED_OTHER": "その他の理由",
      "DOCUMENT_SCREENING_EVALUATED": "評価済",
      "DOCUMENT_SCREENING_WAITING_FOR_ASSIGNMENT": "依頼前",
      "DOCUMENT_SCREENING_WAITING_FOR_EVALUATION": "依頼済み 未評価",
      "ENTRY": "エントリー",
      "INFORMATION_REGISTRATION": "情報登録",
      "INTERVIEW_ADJUSTMENT": "面接調整中",
      "INTERVIEW_EVALUATED": "面接評価済み",
      "INTERVIEW_WAITING_FOR_ASSIGNMENT": "面接設定前",
      "INTERVIEW_WAITING_FOR_EVALUATION": "面接設定済み 未評価",
      "JOINED": "その他の理由",
      "OFFER": "内定",
      "OFFER_ACCEPTED": "内定承諾",
      "REJECTED_CULTURE_MISMATCH": "不合格 (カルチャー不一致)",
      "REJECTED_OTHER": "不合格 (その他)",
      "REJECTED_SKILL_MISMATCH": "不合格 (スキル不一致)",
      "WITHDRAWN_OTHER": "辞退 (その他)",
      "WITHDRAWN_OTHER_OFFER": "辞退 (他社内定)",
      "WITHDRAWN_REMAINED": "辞退 (現職継続)"
    },
    "recruitmentStepStatusAction": {
      "ADJUST_INTERVIEW": "調整中の面接を編集",
      "ARCHIVE": "アーカイブ",
      "ASSIGN_FOR_DOCUMENT_SCREENING_EVALUATION": "書類選考を依頼",
      "ASSIGN_FOR_INTERVIEW": "面接を設定",
      "ASSIGN_TO_SESSION_MANUALLY": "手動でセッションに割り当てる",
      "COMPLETE_EVALUATION": "評価を完了する",
      "DECLINE_SELECTION": null,
      "CONFIRM_DOCUMENTS": "書類を確認する",
      "EVALUATE_DOCUMENT_SCREENING": "評価を入力",
      "EVALUATE_INTERVIEW": "評価を入力",
      "FILL_EVALUATION": "評価を記入する",
      "FINALIZE_FAIL": "不合格を最終決定する",
      "FINALIZE_PASS": "合格を最終決定する",
      "FINISH_SELECTION_DUE_TO_JOIN": "入社のため選考を終了",
      "FINISH_SELECTION_DUE_TO_OTHER_REASONS": null,
      "MARK_AS_FAIL": "不合格（暫定）",
      "MARK_AS_JOINED": "入社済み登録",
      "MARK_AS_PASS": "合格（暫定）",
      "MARK_AS_UNDECIDED": "未決定（暫定）",
      "MOVE_TO_NEXT_STATUS": "次のステータスに移動する",
      "MOVE_TO_NEXT_STEP": "次のステップに移動",
      "REGISTER_ATTENDANCE": "出席を登録する",
      "REJECT_SELECTION": null,
      "REVERT_TO_PREVIOUS_STATUS": "選考中に戻す",
      "SEND_DOCUMENT_NOTIFICATION": "書類通知を送る",
      "SEND_EVENT_ANNOUNCEMENT": "イベント告知を送る",
      "SEND_FOLLOW_UP_EMAIL": "フォローアップメールを送る",
      "SEND_REMINDER_EMAIL": "リマインダーメールを送る",
      "SEND_REMINDER_TO_PENDING_EXAMINERS": "評価者にリマインダーを送る",
      "SET_JOB_OFFER_ACCEPTED_DATE": "内定承諾に移動",
      "SET_JOB_OFFER_DATE": "内定に移動",
      "VIEW_EVALUATIONS": "評価を確認する"
    },
    "recruitmentStepStatusOtherActions": {
      "ADJUST_INTERVIEW": null,
      "ASSIGN_FOR_DOCUMENT_SCREENING_EVALUATION": null,
      "ASSIGN_FOR_INTERVIEW": null,
      "DECLINE_SELECTION": "辞退のため選考を終了",
      "EVALUATE_DOCUMENT_SCREENING": "評価を入力",
      "EVALUATE_INTERVIEW": "評価を入力",
      "FINISH_SELECTION_DUE_TO_JOIN": "入社のため選考を終了",
      "FINISH_SELECTION_DUE_TO_OTHER_REASONS": "その他の理由で選考を終了",
      "MOVE_TO_NEXT_STEP": "次のステップに移動",
      "REJECT_SELECTION": "不合格のため選考を終了",
      "REVERT_TO_PREVIOUS_STATUS": "1つ前に戻す",
      "SET_JOB_OFFER_ACCEPTED_DATE": null,
      "SET_JOB_OFFER_DATE": "内定に移動"
    },
    "recruitmentStepStatusV2": {
      "CLOSED_NO_REPLY": "返信無し・面接欠席",
      "CLOSED_OTHER": "その他の理由",
      "JOINED": "入社済み",
      "PENDING_ATTENDANCE": "出席待ち",
      "PENDING_DECISION": "決定待ち",
      "PENDING_DOCUMENT": "書類待ち",
      "PENDING_EVALUATION": "評価待ち",
      "PENDING_EVENT": "イベント待ち",
      "PENDING_EVENT_ANNOUNCEMENT": "イベント告知待ち",
      "PENDING_FINAL_DECISION": "最終決定待ち",
      "PENDING_NOTIFICATION": "通知待ち",
      "PENDING_RESULT": "結果待ち",
      "REJECTED_CULTURE_MISMATCH": "不合格 - カルチャー不一致",
      "REJECTED_OTHER": "不合格 - その他",
      "REJECTED_SKILL_MISMATCH": "不合格 - スキル不一致",
      "SCHEDULE_ADJUSTMENT": "スケジュール調整",
      "WITHDRAWN_OTHER": "辞退 - その他",
      "WITHDRAWN_OTHER_OFFER": "辞退 - 他社内定",
      "WITHDRAWN_REMAINED": "辞退 - 現職継続"
    },
    "recruitmentStepType": {
      "CLOSED": null,
      "DOCUMENT_SCREENING": "書類選考",
      "ENTRY": "エントリー",
      "INFORMATION_REGISTRATION": "情報登録",
      "INTERVIEW": "イベント",
      "OFFER": "内定",
      "OFFER_ACCEPTED": "内定承諾",
      "RecruitmentResult": "選考結果"
    },
    "recruitmentStepTypeV2": {
      "DOCUMENT": "書類選考",
      "EVENT": "イベント",
      "SYSTEM": "システム"
    },
    "schoolTypes": {
      "GRADUATE_SCHOOL": "大学院",
      "HIGH_SCHOOL": "高校",
      "JUNIOR_COLLEGE": "短期大学",
      "TECHNICAL_COLLEGE": "高等専門学校",
      "UNIVERSITY": "大学",
      "VOCATIONAL_SCHOOL": "専門学校"
    },
    "settings": {
      "list": {
        "title": "設定"
      },
      "preferences": {
        "emailSignature": {
          "externalEmailSignature": "社外メール署名",
          "externalEmailSignatureDescription": "応募者にメールを送る際に使用されます。",
          "externalEmailPlaceholder": "社外メール署名を入力してください",
          "internalEmailSignature": "社内メール署名",
          "internalEmailSignatureDescription": "会社の他のユーザーにメールを送る際に使用されます。",
          "internalEmailPlaceholder": "社内メール署名を入力してください",
          "noExternalSignature": "外部メール署名が設定されていません",
          "noInternalSignature": "社内メール署名が設定されていません",
          "success": "プリファレンスの更新に成功しました",
          "fail": "プリファレンスの更新に失敗しました"
        },
        "title": "プリファレンス",
        "discardNotification": "変更を破棄しました",
        "promptModal": {
          "title": "変更が保存されていません",
          "description": "保存せずに終了すると、加えられた変更はすべて破棄されます。 終了してもよろしいですか？",
          "actions": {
            "proceed": "変更を破棄する",
            "cancel": "編集を続ける",
            "saveAndProceed": "保存して終了する"
          }
        }
      },
      "tabs": {
        "account": "アカウント",
        "comingSoon": "まもなく登場",
        "preferences": "プリファレンス",
        "templates": "テンプレート",
        "userManagement": "ユーザー管理"
      },
      "account": {
        "title": "アカウント",
        "actions": {
          "save": {
            "button": "保存",
            "success": "アカウント設定の更新に成功しました",
            "error": "アカウント設定の更新に失敗しました"
          },
          "discardNotification": "変更を破棄しました",
          "reset": {
            "button": "リセット"
          }
        },
        "section": {
          "password": {
            "heading": "新しいパスワードの設定",
            "subheading": "新しいパスワードは、現在のパスワードやユーザーIDと同じにすることはできません。"
          }
        },
        "form": {
          "firstName": {
            "title": "名"
          },
          "lastName": {
            "title": "姓"
          },
          "firstNameKana": {
            "title": "名（カナ）"
          },
          "lastNameKana": {
            "title": "姓（カナ）"
          },
          "email": {
            "title": "メールアドレス"
          },
          "oldPassword": {
            "title": "現在のパスワード",
            "placeholder": "現在のパスワードを入力してください"
          },
          "newPassword": {
            "title": "新しいパスワード",
            "placeholder": "新しいパスワードを入力してください",
            "patterns": {
              "minimumLength": "8文字以上",
              "halfWidthNumbers": "半角数字（例: 1, 2, 3）",
              "halfWidthUpperCaseLetters": "半角英字 大文字（例: A, B, C）",
              "halfWidthLowerCaseLetters": "半角英字 小文字（例: a, b, c）"
            }
          },
          "newPasswordConfirm": {
            "title": "新しいパスワード （確認用）",
            "placeholder": "新しいパスワードの確認"
          }
        },
        "promptModal": {
          "title": "変更が保存されていません",
          "description": "保存せずに終了すると、加えられた変更はすべて破棄されます。 終了してもよろしいですか？",
          "actions": {
            "proceed": "変更を破棄する",
            "cancel": "編集を続ける",
            "saveAndProceed": "保存して終了する"
          }
        }
      },
      "userManagement": {
        "addUser": "ユーザーの追加",
        "addUserError": {
          "contactUs": "問い合わせをする",
          "description": "招待メールの送信に失敗しました",
          "subDescription": "もう一度お試しください。問題が解決しない場合は開発チームまでお問い合わせください。"
        },
        "create": {
          "description": "Bluumhireにユーザーを追加するには、招待したい人のメールアドレスを入力し、役割を設定してください。",
          "form": {
            "email": {
              "placeholder": "メールアドレスを入力",
              "title": "メールアドレス"
            },
            "role": "役割"
          },
          "title": "新規ユーザーの追加",
          "sendInvite": "招待メールを送信"
        },
        "list": {
          "emptySearch": {
            "description": "検索結果なし",
            "subDescription": "検索条件に一致する求人がありません。別のキーワードをお試しください。"
          },
          "orderBy": "並べ替え",
          "pending": "申請中",
          "role": {
            "ScovilleAdmin": "スコヴィルの管理者",
            "Scoville": "スコヴィルのユーザー",
            "Admin": "管理者",
            "Coordinator": "採用担当者",
            "Examiner": "選考官",
            "Unknown": "不明"
          },
          "rowsPerPage": "ページあたりの行数",
          "search": "検索する",
          "sort": {
            "nameAsc": "ユーザー名 (降順)",
            "nameDesc": "ユーザー名(昇順)"
          },
          "status": {
            "INVITED": "招待",
            "REGISTERED": "有効"
          },
          "table": {
            "email": "メールアドレス",
            "role": "権限",
            "status": "ステータス",
            "username": "ユーザー名"
          },
          "usersCount": "<highlight>{{startRowIndex}} - {{endRowIndex}}</highlight> ユーザー中 <highlight>{{count}}</highlight> ユーザー"
        },
        "subtitle": "ユーザーの追加、削除、権限の変更などが行えます。",
        "title": "ユーザー管理",
        "updateUserError": {
          "contactUs": "問い合わせをする",
          "description": "ユーザー詳細の保存に失敗しました",
          "subDescription": "もう一度お試しください。問題が解決しない場合は開発チームまでお問い合わせください。"
        }
      }
    },
    "sideMenu": {
      "analytics": "リポート",
      "calendar": "カレンダー",
      "candidates": "候補者管理",
      "dashboard": "ダッシュボード",
      "english": "English",
      "japanese": "日本語",
      "job": "求人一覧",
      "logout": "ログアウト",
      "settings": "設定",
      "userRolesTooltip": "あなたのアカウントには、{{roles}}の役割があります。"
    },
    "storybook": {
      "disabled": "無効"
    },
    "users": {
      "create": {
        "invitationEmail": {
          "send": {
            "error": "招待メールの送信が出来ませんでした。",
            "success": "招待メールの送信に成功しました"
          }
        },
        "labels": {
          "email": "メールアドレス",
          "role": "役割"
        },
        "title": "新規ユーザーの追加",
        "tooltips": {
          "scovilleAdmin": null,
          "scoville": null,
          "admin": "ユーザーアカウントを管理し、企業アカウント情報を編集できます。",
          "coordinator": "求人と候補者の選択を管理できます。",
          "examiner": "面接を評価し、割り当てられた書類を審査することができます。"
        }
      },
      "edit": {
        "buttons": {
          "relinquish": "権限を放棄し保存",
          "save": "保存"
        },
        "confirmation": {
          "description": {
            "part1": null,
            "part2": null
          },
          "question": null,
          "title": "管理者権限を放棄しますか？",
          "warning": {
            "INVISIBLE": null,
            "VIEW_SCOVILLE_USER": null,
            "INVITE_SCOVILLE_USER": null,
            "GIVE_SCOVILLE_ROLE": null,
            "INVITE_USER": null,
            "EDIT_USER": null
          }
        },
        "error": "ユーザー情報の更新に失敗しました。",
        "labels": {
          "email": "メールアドレス",
          "firstName": "名",
          "firstNameKana": "名（カナ）",
          "lastName": "姓",
          "lastNameKana": "姓（カナ）",
          "roles": "保存"
        },
        "placeholders": {
          "email": "メールアドレス",
          "firstName": "名",
          "lastName": "姓",
          "firstNameKana": "タナカ",
          "lastNameKana": "タロウ"
        },
        "success": "ユーザー情報が更新されました"
      },
      "errors": {
        "emailAlreadyRegistered": "メールアドレスは既に登録されています。",
        "emailAlreadyInvited": "メールアドレスは既に招待されています。"
      },
      "role": {
        "ScovilleAdmin": "スコヴィルの管理者",
        "Scoville": "スコヴィルのユーザー",
        "Admin": "管理者",
        "Coordinator": "採用担当者",
        "Examiner": "選考官",
        "Unknown": "不明"
      },
      "show": {
        "description": "「管理者」ロールを持つユーザーのみがユーザーの詳細を変更できます。",
        "invitedDescription": "このユーザーはまだアカウントを有効化していません。アカウントが有効になると、その詳細を編集できるようになります。",
        "labels": {
          "email": "メールアドレス",
          "firstName": "名",
          "firstNameKana": "名（カナ）",
          "lastName": "姓",
          "lastNameKana": "姓（カナ）",
          "roles": "保存"
        },
        "resendInvitation": "招待メールを再送する",
        "cancelInvitation": "招待メールを取り消す",
        "scovilleUserDescription": "このユーザーは「スコヴィルの管理者」です。他の「スコヴィルの管理者」ユーザーのみがそのユーザー情報を変更できます。",
        "title": "ユーザー詳細",
        "deleted": {
          "fullName": "{{fullName}} [削除されたユーザー]"
        }
      },
      "resendInvitation": {
        "description": "招待メールの再送信",
        "subDescription": "招待メールを再送信すると、以前の招待リンクは無効になります。続行しますか？",
        "confirm": "招待メールを再送する",
        "resendSuccess": "招待メールを再送しました",
        "resendFailed": "招待メールの再送に失敗しました"
      },
      "cancelInvitation": {
        "description": "招待を取り消す",
        "subDescription": "<highlight>{{email}}</highlight>への招待を取り消しますか? この操作は元に戻せません。",
        "confirm": "招待を取り消す",
        "cancelSuccess": "招待メールを取り消しました",
        "cancelFailed": "招待メールの取り消しに失敗しました"
      }
    },
    "time": {
      "hours": "時",
      "minutes": "分"
    },
    "welcome": {
      "content": {
        "slide1": {
          "title": "カスタマイズ可能な求人情報",
          "caption": "職種を作成して採用フローを設定し、自動メールを有効にして評価フォームを作成します。"
        },
        "slide2": {
          "title": "柔軟な候補者管理",
          "caption": "候補者情報をまとめてインポートおよびエクスポートし、数回クリックするだけで複数の候補者を処理します。"
        },
        "slide3": {
          "title": "反復的なタスクを削減する",
          "caption": "個別または複数の候補者に自動電子メール、評価フォーム、イベントへの招待を設定します。"
        }
      },
      "createFirstJob": "最初の求人を作る",
      "description": "Bluumhireへようこそ",
      "dontShowAgain": "二度と表示しない",
      "subDescription": "Bluumhire は、会社の採用活動の追跡と管理を支援します。"
    },
    "activityLog": {
      "timestamp": "{{date}}、{{time}}"
    }
  }
}