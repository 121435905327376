import { Cn } from "@helpers/unsorted/classNames"
import { Button } from "@shared/unsorted/Button/Button"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { Modal } from "@shared/unsorted/Modal/Modal"
import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { Trans, useTranslation } from "react-i18next"

const styles = {
    container: Cn.c("py-6 rounded-xl"),
    header: Cn.c("flex justify-between items-start pb-4 px-6"),
    outerCircle: Cn.c("w-14 h-14 flex items-center bg-surface-critical-subdued justify-center rounded-full"),
    innerCircle: Cn.c("w-10 h-10 flex items-center bg-surface-critical-default justify-center rounded-full"),
    warningIcon: Cn.c("w-5 h-5 text-icons-critical-emphasized"),
    closeIcon: Cn.c("w-6 h-6 text-emphasized"),
    body: Cn.c("pb-5 border-b border-default px-6"),
    description: Cn.c("text-emphasized font-h4-bold mb-2"),
    subDescription: Cn.c("text-subdued font-paragraph-small-regular"),
    buttons: Cn.c("mt-6 flex space-x-3 justify-end px-6"),
    highlightEmail: Cn.c("text-emphasized font-paragraph-small-semi-bold"),
}

interface Props extends ModalProps {
    onReject: VoidFunction
    onConfirm: VoidFunction
    email: string
}

const CancelInvitationModal: FunctionComponent<Props> = ({
    isOpen,
    onReject,
    onConfirm,
    email
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            close={onReject}
            widthClass="w-[30rem]"
        >
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.outerCircle}>
                        <div className={styles.innerCircle}>
                            <Icon name="errorWarning" className={styles.warningIcon} />
                        </div>
                    </div>
                    <Icon name="close" className={styles.closeIcon} onClick={onReject} />
                </div>
                <div className={styles.body}>
                    <p className={styles.description}>{t('users.cancelInvitation.description')}</p>
                    <p className={styles.subDescription}>
                        <Trans
                            i18nKey={'users.cancelInvitation.subDescription'}
                            values={{ email }}
                            components={{ highlight: <span className={styles.highlightEmail}></span> }}
                        /></p>
                </div>
                <div className={styles.buttons}>
                    <Button
                        variant="secondary"
                        size="md"
                        onClick={onReject}
                    >
                        {t('global.cancel')}
                    </Button>
                    <Button
                        variant="destructiveFilled"
                        size="md"
                        onClick={onConfirm}
                    >
                        {t('users.cancelInvitation.confirm')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {
    CancelInvitationModal
}