import { AllRoless_AllRolesQuery, Client_OneByIdQuery, RegisteredClient } from '@entities';
import { validations } from '@helpers/unsorted/validation';
import { Button } from '@shared/unsorted/Button/Button';
import { CheckboxGroup } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { Input } from '@shared/unsorted/Input/Input';
import { Modal } from '@shared/unsorted/Modal/Modal';
import { Entity } from '@typedefs/graphql';
import { FunctionComponent } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserRelinquishPermissionConfirmModal } from './UserRelinquishPermissionConfirmationModal/UserRelinquishPermissionConfirmModal';
import { useEditable } from './hook';
import { UserInfoUpdateErrorModal } from './UserInfoUpdateErrorModal/UserInfoUpdateErrorModal';
import { Icon } from '@shared/unsorted/Icon/Icon';
import { ModalProps } from '@typedefs/props';
import { Cn } from '@helpers/unsorted/classNames';

const styles = {
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    header: Cn.c('flex justify-between items-start pb-5 pt-6 px-6 border-b border-b-default shrink-0'),
    titleContainer: Cn.c('flex flex-col space-y-4'),
    title: Cn.c('font-h4-bold text-emphasized'),
    iconContainer: Cn.c('p-3 w-fit border border-default rounded'),
    icon: Cn.c('w-6 h-6 text-icons-emphasized'),
    body: Cn.c('p-6 space-y-6 border-b border-default overflow-y-auto'),
    roles: Cn.c('flex flex-wrap gap-2'),
    buttons: Cn.c('flex items-center justify-between space-x-3 p-6'),
    bodyContainer: Cn.c('flex flex-col min-h-0'),
    form: Cn.c('text-default font-paragraph-small-medium'),
    formRow: Cn.c('flex flex-row justify-between flex-1 space-x-4'),
    tooltip: Cn.c('w-fit'),
    roleOptionsTooltipIcon: Cn.c('w-4 h-4 text-icons-subdued'),
    modal: Cn.c('flex flex-col'),
};

interface Props extends ModalProps {
    client?: Omit<RegisteredClient, 'showWelcome' | 'status'>
    closeModal: VoidFunction,
    availableRoles: Entity<AllRoless_AllRolesQuery, 'roles'>[],
    isOnlyAdmin: boolean,
}

const EditableRegisteredUserInfoModal: FunctionComponent<Props> = ({
    client,
    closeModal,
    availableRoles,
    isOnlyAdmin,
    isOpen
}) => {
    const { t } = useTranslation();

    const {
        form,
        roleOptions,
        onSubmit,
        showConfirmation,
        rejectConfirmation,
        approveConfirmation,
        isLoading,
        relinquishedPermissionsState,
        showUpdateUserError,
        hideUpdateUserError,
    } = useEditable(
        availableRoles,
        closeModal,
        isOnlyAdmin,
        client,
    );

    const { clearErrors, control, formState: { errors } } = form;

    const onCloseModal = () => !showConfirmation && !showUpdateUserError && closeModal();

    return (
        <Modal close={onCloseModal} isOpen={isOpen} className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}><Icon name="user" className={styles.icon} /></div>
                    <p className={styles.title}>{t("users.show.title")}</p>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={onCloseModal} />
            </div>
            <form onSubmit={onSubmit} className={styles.bodyContainer}>
                <div className={styles.body}>
                    <div className={styles.form}>
                        <div className={styles.formRow}>
                            <Controller
                                name="lastName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.lastName"
                                        placeholder="users.edit.placeholders.lastName"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("lastName")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.firstName"
                                        placeholder="users.edit.placeholders.firstName"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("firstName")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.formRow}>
                            <Controller
                                name="lastNameKana"
                                control={control}
                                rules={{ required: true, pattern: validations.katakana }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.lastNameKana"
                                        placeholder="users.edit.placeholders.lastNameKana"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("lastNameKana")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                            <Controller
                                name="firstNameKana"
                                control={control}
                                rules={{ required: true, pattern: validations.katakana }}
                                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                    <Input
                                        required
                                        name={name}
                                        label="users.edit.labels.firstNameKana"
                                        placeholder="users.edit.placeholders.firstNameKana"
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        clearErrors={() => clearErrors("firstNameKana")}
                                        forwardedRef={ref}
                                        value={value}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { name, onBlur, onChange, ref, value } }) => (
                                <Input
                                    required
                                    name={name}
                                    label="users.edit.labels.email"
                                    placeholder="users.edit.placeholders.email"
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    clearErrors={() => clearErrors("email")}
                                    forwardedRef={ref}
                                    value={value}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.form}>
                        <Controller
                            name="roleIds"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { value, onChange } }) => (
                                <CheckboxGroup
                                    name="roleIds"
                                    label="users.create.labels.role"
                                    required
                                    onChange={onChange}
                                    value={value}
                                    options={roleOptions}
                                    errors={errors}
                                    tooltipClassName={styles.tooltip}
                                    tooltipIconClassName={styles.roleOptionsTooltipIcon}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button size="md" variant="secondary" onClick={onCloseModal} isFull>{t("global.cancel")}</Button>
                    {/* TODO: Fix. Need to disable save when form is not dirty. Need to debug why sometimes isDirty is not working properly when selected roles change */}
                    <Button size="md" type="submit" isLoading={isLoading} isFull>{t("users.edit.buttons.save")}</Button>
                </div>
            </form>
            <UserRelinquishPermissionConfirmModal
                isOpen={showConfirmation}
                onReject={rejectConfirmation}
                onConfirm={approveConfirmation}
                relinquishedPermissionsState={relinquishedPermissionsState}
            />
            <UserInfoUpdateErrorModal isOpen={showUpdateUserError} onClose={hideUpdateUserError} />
        </Modal>
    );
};

export {
    EditableRegisteredUserInfoModal
};
