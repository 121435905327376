import { useLocation, useNavigate, matchPath } from "react-router-dom";
import { useListPageTrackerContext } from "../../contexts/ListPageTrackerContext";
import { Home } from "@routes/home";
import { JobPosition, JobPositionDetail } from "@routes/jobPosition";
import { EventDetail } from "@routes/event";
import { EventSessionDetail } from "@routes/eventSession";

export const useBackToPreviousListPage = () => {
    const navigate = useNavigate();
    const [lastestListPageRoute] = useListPageTrackerContext()
    const location = useLocation()

    const listPageGuesser = () => {
        if (matchPath({ path: JobPositionDetail.PATH_NAME }, location.pathname)
            || matchPath({ path: EventDetail.PATH_NAME }, location.pathname)
            || matchPath({ path: EventSessionDetail.PATH_NAME }, location.pathname)) {
            const route = JobPosition.toRoute()
            return `${route.PATH_NAME}${route.search}`
        }

        return Home.PATH_NAME
    }

    //TODO: update the logic to get all the previous list page's search params
    const backToPreviousListPage = () => {
        if (lastestListPageRoute) {
            navigate(`${lastestListPageRoute.PATH_NAME}${lastestListPageRoute.search}`);
            return
        }

        const lastestListPageGuess = listPageGuesser()
        navigate(lastestListPageGuess)
    };

    return {
        backToPreviousListPage
    }
}