import {
    Account as AccountRoute,
    Preferences as PreferencesRoute,
    Settings,
    UserManagement as UserManagementRoute,
} from "@routes/setting";
import { Cn } from "@helpers/unsorted/classNames";
import { DefaultLayout } from "@shared/layout/DefaultLayout/DefaultLayout";
import { Content as TabContent } from "@shared/layout/tabs/Content";
import { Tab } from "@shared/layout/tabs/Tab";
import { useTabs } from "@shared/layout/tabs/useTabs";
import { PageHeader } from "@shared/unsorted/PageHeader/PageHeader";
import { FunctionComponent } from "react";
import { Preferences } from "./Preferences/Preferences";
import { Account } from "./Account/Account";
import { UserInviteModal } from "./UserInviteModal/UserInviteModal";
import { UserManagement } from "./UserManagement/UserManagement";
import { useSettings } from "./hook";
import { useTranslation } from "react-i18next";
import { ReadOnlyRegisteredUserInfoModal } from "./UserInfoModal/ReadOnlyRegisteredUserInfoModal/ReadOnlyRegisteredUserInfoModal";
import { EditableRegisteredUserInfoModal } from "./UserInfoModal/EditableRegisteredUserInfoModal/EditableRegisteredUserInfoModal";
import { ReadOnlyInvitedUserInfoModal } from "./UserInfoModal/ReadOnlyInvitedUserInfoModal/ReadOnlyInvitedUserInfoModal";

const tabs = ["users", "account", "preferences"] as const;

type SettingsTab = (typeof tabs)[number];

const styles = {
    breadcrumb: Cn.c("py-4 border-b border-b-default px-6"),
    pageTitle: Cn.c('font-h2-bold p-6 text-emphasized'),
    body: Cn.c("pb-8 flex overflow-auto flex-col flex-1"),
    tabList: Cn.c("mx-6"),
    tabContent: Cn.c("px-6 overflow-auto flex flex-col flex-1"),
    tabContainer: Cn.c("overflow-auto flex flex-col flex-1"),
};

interface Props {
    defaultTab: SettingsTab;
}

// @ocaml.doc("The user management page")
const SettingsPage: FunctionComponent<Props> = ({ defaultTab }) => {
    const {
        state,
        getUserModalVariant,
        closeModal,
    } = useSettings();

    const userManagementRoute = UserManagementRoute.toRoute();

    const { ControlledTabs } = useTabs(defaultTab);

    const { t } = useTranslation()

    return (
        <DefaultLayout
            extra={
                <>
                    <UserInviteModal
                        isOpen={state.displayMode.name === "create"}
                        roles={
                            state.displayMode.name === "create"
                                ? state.displayMode.payload.roles
                                : []
                        }
                    />
                    <ReadOnlyRegisteredUserInfoModal
                        isOpen={getUserModalVariant(state.displayMode) === 'registeredReadOnly'}
                        closeModal={closeModal}
                        client={state.displayMode.name === 'show' && state.displayMode.payload.client.__typename === 'RegisteredClient'
                            ? state.displayMode.payload.client
                            : undefined}
                    />
                    <EditableRegisteredUserInfoModal
                        isOpen={getUserModalVariant(state.displayMode) === 'registeredEditable'}
                        closeModal={closeModal}
                        client={state.displayMode.name === 'show' && state.displayMode.payload.client.__typename === 'RegisteredClient'
                            ? state.displayMode.payload.client
                            : undefined}
                        availableRoles={state.displayMode.name === 'show'
                            ? state.displayMode.payload.roles
                            : []}
                        isOnlyAdmin={!state.loading && state.isOnlyAdmin}
                    />
                    <ReadOnlyInvitedUserInfoModal
                        isOpen={getUserModalVariant(state.displayMode) === 'invitedReadOnly'}
                        closeModal={closeModal}
                        client={state.displayMode.name === 'show' && state.displayMode.payload.client.__typename === 'InvitedClient'
                            ? state.displayMode.payload.client
                            : undefined}
                    />
                </>
            }
        >
            <div className={styles.pageTitle}>{t('sideMenu.settings')}</div>
            <div className={styles.body}>
                <ControlledTabs
                    className={styles.tabContainer}
                    tabListClassName={styles.tabList}
                    tabChildClassName={styles.tabContent}
                >
                    <Tab
                        id="account"
                        label="settings.tabs.account"
                        route={AccountRoute.PATH_NAME}
                    />
                    <Tab
                        id="users"
                        label="settings.tabs.userManagement"
                        route={`${userManagementRoute.PATH_NAME}${userManagementRoute.search}`}
                    />
                    <Tab
                        id="preferences"
                        label="settings.tabs.preferences"
                        route={PreferencesRoute.PATH_NAME}
                    />

                    <TabContent id="account">
                        <Account />
                    </TabContent>
                    <TabContent id="users">
                        <UserManagement />
                    </TabContent>
                    <TabContent id="preferences">
                        <Preferences />
                    </TabContent>
                </ControlledTabs>
            </div>
        </DefaultLayout>
    );
};

export { SettingsPage };
