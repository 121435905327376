import { Route } from "@routes/hook";
import { createContext, useContext } from "react";

type ListPageTrackerContextType = [lastestListPageRoute: Route | undefined, setLastestListPageRoute: (value: Route | undefined) => void]

const ListPageTrackerContext = createContext<ListPageTrackerContextType | undefined>(undefined);

const ListPageTrackerProvider = ListPageTrackerContext.Provider

const useListPageTrackerContext = () => {
    const context = useContext(ListPageTrackerContext)
    if (!context) {
        throw new Error('This component must be used inside a <ListPageTrackerProvider>...</ListPageTrackerProvider> block.')
    }
    return context
}

export {
    ListPageTrackerContext,
    ListPageTrackerProvider,
    ListPageTrackerContextType,
    useListPageTrackerContext
}