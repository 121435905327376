import {
  AllAcquisitionCategories_AllDocument,
  AllEntryCategories_AllDocument,
  AllPrefecturesDocument,
  AllSchoolTypesDocument,
  Applications_AllApplicationDocument,
  ExportCandidateCsvDocument,
  JobPositions_AllSimpleDocument,
  RecruitmentProcess_OneByIdDocument,
  SupportedLanguage,
} from "@entities";
import { hasValue, isNonNull } from "@helpers/core/typeGuards";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { dateFormat } from "@helpers/unsorted/dateFormat";
import { downloadUrl } from "@helpers/unsorted/downloadUrl";
import { handleResponse, responseHasError, useQueryContext } from "@helpers/unsorted/urqlExtra";
import { SelectionOption } from "@typedefs/selectOption";
import { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "urql";
import { ApplicationModalMode, BulkActionModalMode, State, getInitialState, reducer } from "./reducer";
import { CsvExportActions } from "./ApplicationPanel/CSVExportActions/hook";
import { useCurrentLanguage } from "@helpers/core/i18n";
import { useFakeProgress } from "@helpers/hooks/unsorted/fakeProgressHook";
import { Candidate } from "@routes/candidate";
import { useListPageTrackerContext } from '../../../contexts/ListPageTrackerContext';

type CsvExportDataType = {
  fileName: string;
  downloadUrl: string;
};

interface ApplicationsPageHookType {
  state: State;
  changePage: (currentPage: number) => void;
  rowsPerPageOptions: SelectionOption[];
  changeRowsPerPage: (rowsPerPage: string) => void;
  getPageCount: (totalRows: number) => number;
  currentPage: number;
  rowsPerPage: number;
  openCreateApplicationModal: VoidFunction;
  selectedJobPositionIds?: string[];
  dispatchApplicationModalMode: (applicationModalMode: ApplicationModalMode) => void;
  dispatchBulkActionModalMode: (bulkActionModalMode: BulkActionModalMode) => void;
  closeModal: VoidFunction;
  searchKeyword?: string | null;
  onSubmitSearch: (keyword: string) => void;
  exportCandidatesToCsv: (action: CsvExportActions) => void;
  onCancelCSVExport: VoidFunction;
  onCSVExportError: VoidFunction;
  onRetryCSVExport: VoidFunction;
  csvExportProgress: number;
}

const useApplicationsPage = (): ApplicationsPageHookType => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { error: toastError } = useToast();

  const queryParams = Candidate.useSearchParams();

  const [state, dispatch] = useReducer(reducer, getInitialState());

  const cancelledExportCSVRef = useRef<boolean>(false)

  const currentLanguage = useCurrentLanguage();

  const { start: startProgress, stop: stopProgress, progress } = useFakeProgress({ timeConstant: 10000 });

  const [, setLastestListPageRoute] = useListPageTrackerContext()

  const location = useLocation()

  const csvExportProgress = Math.round(progress * 100);
  const [isCsvExportIdle, setIsCsvExportIdle] = useState(true); // Determines if the CSV export is actively processing or not
  const [csvExportDownloadData, setCsvExportDownloadData] = useState<CsvExportDataType | null>(null);

  const selectedJobPositionIds = queryParams.jobPositionIds.length
    ? queryParams.jobPositionIds
    : undefined;

  const jobPositionsQueryContext = useQueryContext("JobPosition");
  const [jobPositionsResponse,] = useQuery({
    query: JobPositions_AllSimpleDocument,
    context: jobPositionsQueryContext,
    variables: {},
  });

  const applicationsQueryContext = useQueryContext("RecruitmentProcesses");
  const [applicationsResponse] = useQuery({
    query: Applications_AllApplicationDocument,
    context: applicationsQueryContext,
    variables: {
      input: {
        first: queryParams.rowsPerPage,
        offset: queryParams.rowsPerPage * (queryParams.page - 1),
        jobPositionIds: selectedJobPositionIds,
        candidateName: queryParams.search.candidateName,
        sortType: queryParams.sortType,
        order: queryParams.order,
        applicationType: queryParams.applicationType != 'ALL' ? queryParams.applicationType : undefined,
        stepStatuses: queryParams.ongoingStepStatuses,
        stepIds: queryParams.stepIds,
        schoolIds: queryParams.schoolIds,
        and: [
          {
            or: [
              {
                stages: queryParams.stages,
              },
              {
                stepStatuses: queryParams.archivedStepStatuses,
              }
            ]
          }
        ]
      }
    }
  });

  const [exportCandidateCSVResponse, exportCandidateCSV] = useMutation(ExportCandidateCsvDocument);

  const entryCategoriesQueryContext = useQueryContext("EntryCategory");
  const [entryCategoriesResponse] = useQuery({
    query: AllEntryCategories_AllDocument,
    context: entryCategoriesQueryContext,
    pause: queryParams.mode.name === 'list',
  });

  const acquisitionCategoriesQueryContext = useQueryContext("AcquisitionCategory");
  const [acquisitionCategoriesResponse] = useQuery({
    query: AllAcquisitionCategories_AllDocument,
    context: acquisitionCategoriesQueryContext,
    pause: queryParams.mode.name === 'list',
  });

  const recruitmentProcessQueryContext = useQueryContext('RecruitmentProcess')
  const [recruitmentProcessResponse] = useQuery({
    query: RecruitmentProcess_OneByIdDocument,
    variables: {
      recruitmentProcessId: queryParams.mode.name === 'show' ? queryParams.mode.payload.applicationId : '',
    },
    context: recruitmentProcessQueryContext,
    pause: queryParams.mode.name !== 'show',
  });

  const prefecturesQueryContext = useQueryContext('Prefecture')
  const [prefectureResponse] = useQuery({
    query: AllPrefecturesDocument,
    context: prefecturesQueryContext,
    pause: queryParams.mode.name !== 'show',
  })

  const schooldTypesQueryContext = useQueryContext('SchoolType')
  const [schoolTypesResponse] = useQuery({
    query: AllSchoolTypesDocument,
    context: schooldTypesQueryContext,
    pause: queryParams.mode.name !== 'show',
  })

  // Event handlers
  const openCreateApplicationModal = () => {
    dispatch({
      name: 'SetApplicationModalMode',
      applicationModalMode: {
        name: 'addCandidatesModal',
      }
    })
  };

  const changePage = (currentPage: number) => {
    navigate(Candidate.toRoute({
      ...queryParams,
      page: currentPage,
      mode: 'list'
    }))
  }

  const changeRowsPerPage = (rowsPerPage: string) => {
    navigate(Candidate.toRoute({
      ...queryParams,
      page: 1,
      rowsPerPage: parseInt(rowsPerPage),
      mode: 'list'
    }))
  }

  const dispatchApplicationModalMode = (applicationModalMode: ApplicationModalMode) => dispatch({
    name: 'SetApplicationModalMode',
    applicationModalMode
  })

  const dispatchBulkActionModalMode = (bulkActionModalMode: BulkActionModalMode) => dispatch({
    name: 'SetApplicationModalMode',
    applicationModalMode: bulkActionModalMode
  })

  const closeModal = () => dispatch({
    name: 'SetApplicationModalMode',
    applicationModalMode: {
      name: 'hide'
    }
  })

  const onCSVExportError = () => dispatch({
    name: 'SetApplicationModalMode',
    applicationModalMode: {
      name: 'csvExportError'
    }
  })

  const rowsPerPageOptions: SelectionOption[] = [
    {
      label: "10",
      value: "10",
      key: "10"
    },
    {
      label: "25",
      value: "25",
      key: "25"
    },
    {
      label: "50",
      value: "50",
      key: "50"
    },
    //TODO: uncomment these options whenever we ensure the limit from API is ok
    // {
    //   label: "75",
    //   value: "75",
    //   key: "75"
    // },
    // {
    //   label: "100",
    //   value: "100",
    //   key: "100"
    // },
  ]

  const onSubmitSearch = (keyword: string) => {
    navigate(Candidate.toRoute({
      ...queryParams,
      page: 1,
      search: {
        ...queryParams.search,
        candidateName: keyword,
      },
      mode: 'list',
    }));
  };

  const exportCandidatesToCsv = (action: CsvExportActions) => {
    dispatch({
      name: 'SetCSVExportMode',
      csvExportMode: action,
    });

    exportCandidateCSV({ status: action, lang: currentLanguage.toUpperCase() as SupportedLanguage });
  };

  const onCancelCSVExport = () => {
    stopProgress();
    setIsCsvExportIdle(true);
    setCsvExportDownloadData(null);

    cancelledExportCSVRef.current = true;

    dispatch({
      name: 'SetCSVExportMode',
      csvExportMode: 'NONE',
    });

    closeModal();
  };

  const onRetryCSVExport = () => state.csvExportMode !== 'NONE' && exportCandidateCSV({
    status: state.csvExportMode,
    lang: currentLanguage.toUpperCase() as SupportedLanguage,
  });

  // Effects
  useEffect(() => {
    handleResponse(jobPositionsResponse, {
      onFetching: () =>
        dispatch({
          name: "SetLoading",
          loading: true,
        }),
      onData: (data) =>
        dispatch({
          name: "UpdateJobPositions",
          jobPositions: data.jobPositions.edges,
        }),
      onError: (_error) => {
        toastError("global.error");
        dispatch({
          name: "UpdateJobPositions",
          jobPositions: [],
        });
      },
    });
  }, [jobPositionsResponse]);

  useEffect(() => {
    handleResponse(applicationsResponse, {
      onFetching: () =>
        dispatch({
          name: "SetLoading",
          loading: true,
        }),
      onData: (data) =>
        dispatch({
          name: "UpdateApplications",
          applications: data.applications.edges,
          totalRows: data.applications.totalCount,
          clientHasNoProcess: data.applications.clientHasNoProcess,
        }),
      onError: (_error) => {
        toastError("global.error");

        dispatch({
          name: "UpdateApplications",
          applications: [],
          totalRows: 0,
          clientHasNoProcess: true,
        });
      },
    });
  }, [applicationsResponse]);

  useEffect(() => {
    if (queryParams.mode.name === 'list') {
      if (state.displayMode.name !== queryParams.mode.name) {
        dispatch({
          name: 'SetMode',
          displayMode: {
            name: queryParams.mode.name
          }
        });
      }
    }

    const application = recruitmentProcessResponse.data?.recruitmentProcess;
    const prefectures = prefectureResponse.data?.prefectures;
    const schoolTypes = schoolTypesResponse.data?.schoolTypes;

    const entryCategories = entryCategoriesResponse.data?.entryCategories;
    const acquisitionCategories = acquisitionCategoriesResponse.data?.acquisitionCategories;

    if (queryParams.mode.name === 'show' && queryParams.mode.payload.applicationId) {
      if (hasValue(application) && hasValue(prefectures) && hasValue(schoolTypes)) {
        dispatch({
          name: 'SetMode',
          displayMode: {
            name: 'show',
            payload: {
              application,
              prefectures: prefectures || [],
              schoolTypes: schoolTypes || [],
              acquisitionCategories: acquisitionCategories || [],
              entryCategories: entryCategories || [],
            },
          },
        });
      }
    }

    if (responseHasError(entryCategoriesResponse)
      || responseHasError(acquisitionCategoriesResponse)
      || responseHasError(recruitmentProcessResponse)
      || responseHasError(prefectureResponse)
      || responseHasError(schoolTypesResponse)
    ) {
      toastError('global.error');

      navigate(
        Candidate.toRoute({
          mode: 'list',
          page: 1,
          sortType: queryParams.sortType,
          order: queryParams.order,
        }),
      );
    }
  }, [
    entryCategoriesResponse,
    acquisitionCategoriesResponse,
    recruitmentProcessResponse,
    prefectureResponse,
    schoolTypesResponse,
    queryParams.mode,
  ]);

  useEffect(() => {
    const date = dateFormat.simpleDateNoSeparator(new Date());

    const getActionString = () => {
      if (state.csvExportMode === 'NONE') return '';

      switch (state.csvExportMode) {
        case 'ACTIVE':
          return t('csvExportingModal.actions.active');
        case 'ARCHIVE':
          return t('csvExportingModal.actions.archived');
      }
    };

    const fileName = t('applications.exportCandidates.filename', { action: getActionString(), date })

    handleResponse(exportCandidateCSVResponse, {
      onFetching: () => {
        startProgress();

        setIsCsvExportIdle(false);
        setCsvExportDownloadData(null);

        cancelledExportCSVRef.current = false
        dispatch({
          name: 'SetApplicationModalMode',
          applicationModalMode: {
            name: 'csvExporting',
            payload: { fileName }
          }
        })
      },
      onData: (data) => {
        stopProgress();
        setIsCsvExportIdle(true);

        if (!cancelledExportCSVRef.current) {
          setCsvExportDownloadData({ fileName, downloadUrl: data.exportCandidateCSV.downloadUrl })
        }

        dispatch({
          name: 'SetCSVExportMode',
          csvExportMode: 'NONE',
        });
      },
      onError: (_error) => {
        stopProgress();

        setIsCsvExportIdle(true);
        setCsvExportDownloadData(null);

        onCSVExportError()
        toastError('global.error');
      },
    });
  }, [exportCandidateCSVResponse]);

  useEffect(() => {
    if (isCsvExportIdle && csvExportProgress === 100 &&
      !cancelledExportCSVRef.current &&
      isNonNull(csvExportDownloadData)
    ) {
      const { fileName, downloadUrl: downloadUrlString } = csvExportDownloadData;
      downloadUrl(fileName, downloadUrlString);

      setCsvExportDownloadData(null);
      closeModal();
    }
  }, [isCsvExportIdle, csvExportProgress, cancelledExportCSVRef, csvExportDownloadData]);

  useEffect(() => {
    setLastestListPageRoute({
      PATH_NAME: location.pathname,
      search: location.search
    })
  }, [location])


  return {
    state,
    changePage,
    rowsPerPageOptions,
    changeRowsPerPage,
    getPageCount: (totalRows: number) => Math.ceil(totalRows / queryParams.rowsPerPage),
    currentPage: queryParams.page,
    rowsPerPage: queryParams.rowsPerPage,
    openCreateApplicationModal,
    selectedJobPositionIds,
    dispatchApplicationModalMode,
    dispatchBulkActionModalMode,
    closeModal,
    searchKeyword: queryParams.search.candidateName,
    onSubmitSearch,
    exportCandidatesToCsv,
    onCancelCSVExport,
    onCSVExportError,
    onRetryCSVExport,
    csvExportProgress,
  };
};

export { useApplicationsPage };
