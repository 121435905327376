import { AllRoless_AllRolesQuery, CreateInvitationDocument, RoleName } from '@entities';
import { useClient } from '@helpers/hooks/unsorted/clientHook';
import { UserManagement } from '@routes/setting';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { getQueryContext, handleResponse, hasErrorCode } from '@helpers/unsorted/urqlExtra';
import { CheckboxOption } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { Entity } from '@typedefs/graphql';
import { FormEventHandler, useEffect, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { getRoleKey } from '../hook';
import * as value from './value';

interface UserInviteModalHookResult {
    close: VoidFunction;
    form: UseFormReturn<value.Encoder.EncoderType>;
    onSubmit: FormEventHandler<HTMLFormElement>;
    isLoading: boolean;
    roleOptions: CheckboxOption[];
    showAddUserError: boolean;
    hideAddUserError: VoidFunction;
}

const useUserInviteModal = (roles: Entity<AllRoless_AllRolesQuery, 'roles'>[]): UserInviteModalHookResult => {
    const queryParams = UserManagement.useSearchParams();

    const navigate = useNavigate();

    const { t } = useTranslation();

    const { currentClient } = useClient();

    const { error: toastError, success: toastSuccess } = useToast();

    const [isLoading, setIsLoading] = useState(false);

    const [showAddUserError, setShowAddUserError] = useState(false);

    const tooltips: Record<RoleName, string> = {
        'ScovilleAdmin': t('users.create.tooltips.scovilleAdmin'),
        'Scoville': t('users.create.tooltips.scoville'),
        'Admin': t('users.create.tooltips.admin'),
        'Coordinator': t('users.create.tooltips.coordinator'),
        'Examiner': t('users.create.tooltips.examiner'),
    };

    const roleOptions: CheckboxOption[] = roles
        .filter(role => {
            if (currentClient.clientState === 'loggedIn') {
                const { clientInfo: currentClientInfo } = currentClient;
                if (currentClientInfo.isScovilleAdmin) {
                    return true;
                } else if (currentClientInfo.isScovilleUser) {
                    return role.name !== 'ScovilleAdmin';
                }
            }

            return !['Scoville', 'ScovilleAdmin'].includes(role.name);
        })
        .map(role => ({
            key: role.id,
            label: t(`users.role.${getRoleKey(role.name)}`),
            value: role.id,
            disabled: false,
            tooltip: tooltips[role.name],
        }));

    const [createInvitationResponse, createInvitation] = useMutation(CreateInvitationDocument);

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: value.Encoder.defaultValues(),
    });

    const close = () => {
        navigate(UserManagement.toRoute({
            ...queryParams,
            mode: 'list',
        }));
        setShowAddUserError(false);
        form.reset();
    };

    const onSubmit: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            createInvitation({
                email: validValues.email,
                roleIds: validValues.roles,
            }, getQueryContext('Clients'));
        } catch {
            setShowAddUserError(true);
        }
    };

    const hideAddUserError = () => setShowAddUserError(false);

    useEffect(() => {
        if (queryParams.mode.name !== 'create') {
            form.clearErrors(['email', 'roles']);
            form.reset(value.Encoder.defaultValues());
        }
    }, [queryParams.mode]);

    useEffect(() => {
        handleResponse(createInvitationResponse, {
            onFetching: () => {
                setIsLoading(true);
            },
            onData: () => {
                setIsLoading(false);
                toastSuccess('users.create.invitationEmail.send.success');
                close();
            },
            onError: (error) => {
                setIsLoading(false);

                if (hasErrorCode(error, 'EMAIL_ALREADY_EXISTS')) {
                    toastError('users.errors.emailAlreadyRegistered');
                } else if (hasErrorCode(error, 'EMAIL_SENDING_FAILURE')) {
                    setShowAddUserError(true);
                } else if (hasErrorCode(error, 'EMAIL_ALREADY_INVITED')) {
                    toastError('users.errors.emailAlreadyInvited');
                } else {
                    setShowAddUserError(true);
                }
            },
        });
    }, [createInvitationResponse]);

    return {
        close,
        form,
        onSubmit: form.handleSubmit(onSubmit),
        isLoading,
        roleOptions,
        showAddUserError,
        hideAddUserError,
    };
};

export {
    useUserInviteModal
};
