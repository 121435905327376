import {
    AllRoless_AllRolesDocument,
    Client_OneByIdDocument,
    GetAllClients_AllClientsDocument
} from '@entities';
import { hasValue, isDefined, isNull, isOneOf } from '@helpers/core/typeGuards';
import { UserManagement } from '@routes/setting';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { handleResponse, responseHasError, useQueryContext } from '@helpers/unsorted/urqlExtra';
import { useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
import { DisplayMode, State, initialState, reducer } from './reducer';
import { extractClientRoles, useClient } from '@helpers/hooks/unsorted/clientHook';


const ACCEPTED_ROLE_KEYS = ['ScovilleAdmin', 'Scoville', 'Admin', 'Coordinator', 'Examiner'] as const;
const getRoleKey = (roleName: string) => isOneOf(ACCEPTED_ROLE_KEYS)(roleName) ? roleName : 'Unknown';

interface SettingsHookResult {
    state: State;
    openUserInfoModal: (clientId: string) => void;
    openUserInviteModal: VoidFunction;
    closeModal: VoidFunction;
    getUserModalVariant: (displayMode: DisplayMode) => UserModalVariant
}

type UserModalVariant = 'registeredEditable' | 'registeredReadOnly' | 'invitedReadOnly' | 'unknown';

const useSettings = (): SettingsHookResult => {
    const navigate = useNavigate();

    const { error: toastError } = useToast();

    const { currentClient } = useClient();

    const queryParams = UserManagement.useSearchParams();

    const [state, dispatch] = useReducer(reducer, initialState);

    const openUserInfoModal = (clientId: string) => {
        navigate(UserManagement.toRoute({
            ...queryParams,
            mode: 'show',
            id: clientId,
        }));
    };

    const openUserInviteModal = () => {
        navigate(UserManagement.toRoute({
            mode: 'create',
        }));
    };

    const clientsQueryContext = useQueryContext("Client");
    const [clientsResponse] = useQuery({
        query: GetAllClients_AllClientsDocument,
        context: clientsQueryContext,
        variables: {
            offset: queryParams.rowsPerPage * (queryParams.page - 1),
            first: queryParams.rowsPerPage,
            clientName: queryParams.search,
            sortType: queryParams.sortType,
            order: queryParams.order,
            recordStatus: 'ACTIVE'
        },
    });

    const getUserModalVariant = (displayMode: DisplayMode): UserModalVariant => {
        if (displayMode.name !== 'show') {
            return 'unknown'
        }

        const { client } = displayMode.payload;

        if (currentClient.clientState !== 'loggedIn' || !isDefined(client)) {
            return 'unknown'
        }

        const { clientInfo: currentClientInfo } = currentClient;

        if (client.__typename === 'RegisteredClient') {
            if (currentClientInfo.isScovilleAdmin) {
                return 'registeredEditable'
            }

            if (currentClientInfo.isScovilleUser) {
                const clientRoles = extractClientRoles(client.roles);
                if (clientRoles.isScovilleAdmin) {
                    return 'registeredReadOnly'
                }

                return 'registeredEditable'
            }

            if (currentClientInfo.isAdmin) {
                return 'registeredEditable'
            }

            return 'registeredReadOnly'
        }

        if (client.__typename === 'InvitedClient') {
            return 'invitedReadOnly'
        }

        return 'unknown'
    }

    const closeModal = () => navigate(UserManagement.toRoute({
        ...queryParams,
        mode: 'list',
        id: '',
    }));

    const clientQueryContext = useQueryContext("Client");
    const [clientResponse] = useQuery({
        query: Client_OneByIdDocument,
        context: clientQueryContext,
        variables: {
            id: queryParams.mode.name === 'show' ? queryParams.mode.payload.id : '',
        },
        pause: queryParams.mode.name !== 'show',
    });

    const rolesQueryContext = useQueryContext("Role");
    const [rolesResponse] = useQuery({
        query: AllRoless_AllRolesDocument,
        context: rolesQueryContext,
        pause: queryParams.mode.name === 'list',
    });

    useEffect(() => {
        handleResponse(clientsResponse, {
            onFetching: () => dispatch({
                name: 'StartLoading',
            }),
            onData: (data) => {
                dispatch({
                    name: 'UpdateClients',
                    clients: data.clients.edges,
                    totalClientsCount: data.clients.totalCount,
                    isOnlyAdmin: data.clients.edges.filter(client =>
                        client.__typename === 'RegisteredClient'
                        && isDefined(client.roles.find(({ name }) => name === 'Admin'))
                    ).length === 1,
                });
            },
            onError: () => {
                dispatch({
                    name: 'UpdateClients',
                    clients: [],
                    totalClientsCount: 0,
                    isOnlyAdmin: false,
                });
                toastError('global.error');
            },
        });
    }, [clientsResponse]);

    useEffect(() => {
        if (queryParams.mode.name === 'list') {
            if (state.displayMode.name !== queryParams.mode.name) {
                dispatch({
                    name: 'SetMode',
                    displayMode: {
                        name: queryParams.mode.name,
                    },
                })
            }
        }

        const selectedClient = clientResponse.data?.client;
        const roles = rolesResponse.data?.roles;

        if (queryParams.mode.name === 'create') {
            if (hasValue(roles)) {
                dispatch({
                    name: 'SetMode',
                    displayMode: {
                        name: queryParams.mode.name,
                        payload: { roles },
                    },
                });
            }
        }

        if (queryParams.mode.name === 'show' && hasValue(queryParams.id)) {
            if (hasValue(selectedClient) && hasValue(roles)) {
                dispatch({
                    name: 'SetMode',
                    displayMode: {
                        name: queryParams.mode.name,
                        payload: {
                            client: selectedClient,
                            roles: roles,
                        },
                    },
                });
            }

            if (responseHasError(clientResponse) || responseHasError(rolesResponse) || !clientResponse.fetching && isNull(selectedClient)) {
                navigate(UserManagement.toRoute());
                toastError('global.error');
            }
        }

    }, [clientResponse, rolesResponse, queryParams.mode]);

    return {
        state,
        openUserInfoModal,
        openUserInviteModal,
        getUserModalVariant,
        closeModal,
    };
};

export {
    getRoleKey,
    useSettings
};
