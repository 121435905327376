import { AllRoless_AllRolesQuery, Client_OneByIdQuery, RegisteredClient, RoleName, UpdateClientDocument } from '@entities';
import { hasNonNullableProperties, isDefined } from '@helpers/core/typeGuards';
import { useClient } from '@helpers/hooks/unsorted/clientHook';
import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { handleResponse, getQueryContext } from '@helpers/unsorted/urqlExtra';
import { getRoleKey } from '@pages/SettingsPage/hook';
import { CheckboxOption } from '@shared/unsorted/CheckboxGroup/CheckboxGroup';
import { Entity } from '@typedefs/graphql';
import { FormEventHandler, useEffect, useRef, useState } from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import * as value from './value';

// Roles sorted in order of permission strength
const ROLE_PERMISSION_ORDER: { [key in RoleName]?: number } = {
    'ScovilleAdmin': 0,
    'Scoville': 1,
    'Admin': 2,
    // Coordinator and Examiner have no permissions
};

const PERMISSIONS = [
    'INVISIBLE',
    'VIEW_SCOVILLE_USER',
    'INVITE_SCOVILLE_USER',
    'GIVE_SCOVILLE_ROLE',
    'INVITE_USER',
    'EDIT_USER',
] as const;

type PermissionType = typeof PERMISSIONS[number];

const ROLE_PERMISSIONS: Record<RoleName, PermissionType[]> = {
    'ScovilleAdmin': ['INVISIBLE', 'VIEW_SCOVILLE_USER', 'INVITE_SCOVILLE_USER', 'INVITE_USER', 'EDIT_USER'],
    'Scoville': ['INVISIBLE', 'VIEW_SCOVILLE_USER', 'INVITE_USER', 'EDIT_USER'], // Scoville User
    'Admin': ['INVITE_USER', 'EDIT_USER'],
    'Coordinator': [],
    'Examiner': [],
};

interface RelinquishedPermissionsState {
    relinquishedRoles: Entity<AllRoless_AllRolesQuery, 'roles'>[];
    lostPermissions: PermissionType[];
}

interface EditableUserInfoModalHookResult {
    form: UseFormReturn<value.Encoder.EncoderType>;
    roleOptions: CheckboxOption[];
    onSubmit: FormEventHandler<HTMLFormElement>;
    showConfirmation: boolean;
    rejectConfirmation: VoidFunction;
    approveConfirmation: VoidFunction;
    isLoading: boolean;
    tooltips: Record<RoleName, string>;
    relinquishedPermissionsState: RelinquishedPermissionsState;
    showUpdateUserError: boolean;
    hideUpdateUserError: VoidFunction;
}

const useEditable = (
    availableRoles: Entity<AllRoless_AllRolesQuery, 'roles'>[],
    close: VoidFunction,
    isOnlyAdmin: boolean,
    client?: Omit<RegisteredClient, 'showWelcome' | 'status'>,
): EditableUserInfoModalHookResult => {
    const { t } = useTranslation();

    const { currentClient } = useClient();

    const [isLoading, setIsLoading] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    const [showUpdateUserError, setShowUpdateUserError] = useState(false);

    const [relinquishedPermissionsState, setRelinquishedPermissionsState] = useState<RelinquishedPermissionsState>({
        relinquishedRoles: [],
        lostPermissions: [],
    });

    const formData = useRef<value.Encoder.EncoderType>();

    const { error: toastError, success: toastSuccess } = useToast();

    const [updateClientResponse, updateClient] = useMutation(UpdateClientDocument);

    const form = useForm<value.Encoder.EncoderType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
        defaultValues: value.Encoder.defaultValues({
            id: client?.id || '',
            firstName: client?.firstName || '',
            lastName: client?.lastName || '',
            firstNameKana: client?.firstNameKana || '',
            lastNameKana: client?.lastNameKana || '',
            email: client?.email || '',
            roles: client?.roles ?? [],
        }),
    });

    const isEditingClientAdmin = isDefined(client?.roles.find(role => role.name === 'Admin'));

    const tooltips: Record<RoleName, string> = {
        'ScovilleAdmin': t('users.create.tooltips.scovilleAdmin'),
        'Scoville': t('users.create.tooltips.scoville'),
        'Admin': t('users.create.tooltips.admin'),
        'Coordinator': t('users.create.tooltips.coordinator'),
        'Examiner': t('users.create.tooltips.examiner'),
    };

    const roleOptions: CheckboxOption[] = availableRoles.map(role => ({
        key: role.id,
        label: t(`users.role.${getRoleKey(role.name)}`),
        value: role.id,
        disabled: role.name === 'Admin' && isOnlyAdmin && isEditingClientAdmin,
        tooltip: tooltips[role.name],
    }));

    const getUserPermissions = (roles: RoleName[]) => {
        const userPermissions = roles.reduce<Set<PermissionType>>((acc, role) => {
            ROLE_PERMISSIONS[role].forEach(permission => acc.add(permission));

            return acc;
        }, new Set<PermissionType>());

        return PERMISSIONS.filter(permission => userPermissions.has(permission));
    };

    const isRelinquishingPermissions = (formRoleIds: string[]) => {
        if (currentClient.clientState === 'loggedIn') {
            const currentRoles = currentClient.clientInfo.roles.map(role => role.name);
            const currentRolePermissions = getUserPermissions(currentRoles);

            const newRoles = availableRoles.filter(role => formRoleIds.includes(role.id)).map(role => role.name);
            const newRolePermissions = getUserPermissions(newRoles);

            // list of permissions that will be lost
            const lostPermissions = currentRolePermissions.filter(permission => !newRolePermissions.includes(permission));

            if (lostPermissions.length > 0) {
                // get removed roles to be used in the confirmation dialog (displayed in order of permission strength)
                // no need to check for Coordinator and Examiner roles as they have no permissions
                const relinquishedRoles = currentClient.clientInfo.roles
                    .filter(role => ROLE_PERMISSIONS[role.name].length > 0 && !newRoles.includes(role.name))
                    .sort((a, b) => {
                        const roleA = ROLE_PERMISSION_ORDER[a.name] ?? Infinity;
                        const roleB = ROLE_PERMISSION_ORDER[b.name] ?? Infinity;

                        return roleA - roleB;
                    });

                setRelinquishedPermissionsState({
                    relinquishedRoles,
                    lostPermissions,
                });

                return true;
            }
        }

        return false;
    };

    const submit = () => {
        if (isDefined(formData.current) && hasNonNullableProperties(formData.current) && isDefined(client)) {
            const { email, firstName, lastName, firstNameKana, lastNameKana, roleIds } = formData.current;

            updateClient(
                {
                    id: client.id,
                    email,
                    firstName,
                    lastName,
                    firstNameKana,
                    lastNameKana,
                    roleIds,
                },
                isEditingCurrentClient ? getQueryContext(['CurrentClient']) : []
            );
        }
    };

    const rejectConfirmation = () => setShowConfirmation(false);

    const approveConfirmation = () => {
        setShowConfirmation(false);
        submit();
    };

    const isEditingCurrentClient = currentClient.clientState === 'loggedIn' && currentClient.clientInfo.id === client?.id;

    const onSave: SubmitHandler<value.Encoder.EncoderType> = async (data) => {
        if (!isDefined(client)) {
            return
        }

        try {
            const validValues = await value.Decoder.schema.parseAsync(data);

            const { firstName, lastName, email, roleIds, firstNameKana, lastNameKana } = validValues;

            formData.current = { id: client.id, firstName, lastName, firstNameKana, lastNameKana, email, roleIds };

            // Only show confirmation when logged-in client/user is editing their own profile
            if (isEditingCurrentClient && isRelinquishingPermissions(roleIds)) {
                setShowConfirmation(true);
            } else {
                submit();
            }
        } catch {
            setShowUpdateUserError(true);
        }
    };

    const hideUpdateUserError = () => setShowUpdateUserError(false);

    useEffect(() => {
        handleResponse(updateClientResponse, {
            onFetching: () => {
                setIsLoading(true);
            },
            onData: () => {
                setIsLoading(false);
                toastSuccess('users.edit.success');
                close();
            },
            onError: () => {
                setIsLoading(false);
                setShowUpdateUserError(true);
            },
        });
    }, [updateClientResponse]);

    useEffect(() => {
        form.reset(value.Encoder.defaultValues({
            id: client?.id || '',
            firstName: client?.firstName || '',
            lastName: client?.lastName || '',
            firstNameKana: client?.firstNameKana || '',
            lastNameKana: client?.lastNameKana || '',
            email: client?.email || '',
            roles: client?.roles ?? [],
        }));
    }, [client]);


    return {
        form,
        roleOptions,
        onSubmit: form.handleSubmit(onSave),
        showConfirmation,
        rejectConfirmation,
        approveConfirmation,
        isLoading,
        tooltips,
        relinquishedPermissionsState,
        showUpdateUserError,
        hideUpdateUserError,
    };
};

export {
    useEditable,
    RelinquishedPermissionsState,
};
