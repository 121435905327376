import { RoleName } from "@entities";
import { ACCEPTED_ROLE_KEYS } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { useClient } from "@helpers/hooks/unsorted/clientHook";
import { useTranslation } from "react-i18next";

const useReadOnlyRegisteredUserInfoModal = () => {
    const { t } = useTranslation();

    const { currentClient } = useClient();

    const getRoleKey = (roleName: string) => isOneOf(ACCEPTED_ROLE_KEYS)(roleName) ? roleName : 'Unknown';

    const getDescription = () => {
        if (currentClient.clientState != 'loggedIn') {
            return undefined;
        }

        return currentClient.clientInfo.isScovilleUser
            ? t('users.show.scovilleUserDescription')
            : t('users.show.description');
    };

    const tooltips: Record<RoleName, string> = {
        'ScovilleAdmin': t('users.create.tooltips.scovilleAdmin'),
        'Scoville': t('users.create.tooltips.scoville'),
        'Admin': t('users.create.tooltips.admin'),
        'Coordinator': t('users.create.tooltips.coordinator'),
        'Examiner': t('users.create.tooltips.examiner'),
    };

    return { tooltips, getDescription, getRoleKey };
};

export {
    useReadOnlyRegisteredUserInfoModal,
};