import { CancelInvitationDocument, ResendInvitationDocument, RoleName } from "@entities";
import { ACCEPTED_ROLE_KEYS } from "@helpers/core/constants";
import { isOneOf } from "@helpers/core/typeGuards";
import { useClient } from "@helpers/hooks/unsorted/clientHook";
import { useToast } from "@helpers/hooks/unsorted/toastHook";
import { getQueryContext, handleResponse } from "@helpers/unsorted/urqlExtra";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "urql";

const useReadOnlyInvitedUserInfoModal = (closeModal: VoidFunction, email?: string) => {
    const { t } = useTranslation();

    const [showResendInvitationModal, setShowResendInvitationModal] = useState(false);

    const [showCancelInvitationModal, setShowCancelInvitationModal] = useState(false);

    const { error: toastError, success: toastSuccess } = useToast();

    const [resendInvitationResponse, resendInvitation] = useMutation(ResendInvitationDocument)

    const [cancelInvitationResponse, cancelInvitation] = useMutation(CancelInvitationDocument)

    const { currentClient } = useClient();

    const getRoleKey = (roleName: string) => isOneOf(ACCEPTED_ROLE_KEYS)(roleName) ? roleName : 'Unknown';

    const getDescription = () => {
        if (currentClient.clientState != 'loggedIn') {
            return undefined;
        }

        return (currentClient.clientInfo.isScovilleAdmin
            || currentClient.clientInfo.isScovilleUser
            || currentClient.clientInfo.isAdmin)
            ? t('users.show.invitedDescription')
            : t('users.show.description');
    };

    const onConfirmResendInvitation = () => {
        if (email) {
            resendInvitation({ email })
        }
    }

    const onConfirmCancelInvitation = () => {
        if (email) {
            cancelInvitation({ email }, getQueryContext('Clients'))
        }
    }

    const tooltips: Record<RoleName, string> = {
        'ScovilleAdmin': t('users.create.tooltips.scovilleAdmin'),
        'Scoville': t('users.create.tooltips.scoville'),
        'Admin': t('users.create.tooltips.admin'),
        'Coordinator': t('users.create.tooltips.coordinator'),
        'Examiner': t('users.create.tooltips.examiner'),
    };

    useEffect(() => {
        handleResponse(resendInvitationResponse,
            {
                onData(data) {
                    setShowResendInvitationModal(false)
                    if (data.resendInvitation) {
                        toastSuccess('users.resendInvitation.resendSuccess')
                    } else {
                        toastError('users.resendInvitation.resendFailed')
                    }

                },
                onError() {
                    setShowResendInvitationModal(false)
                    toastError('users.resendInvitation.resendFailed')
                }
            }
        )
    }, [resendInvitationResponse])

    useEffect(() => {
        handleResponse(cancelInvitationResponse,
            {
                onData(data) {
                    setShowCancelInvitationModal(false)
                    if (data.cancelInvitation) {
                        toastSuccess('users.cancelInvitation.cancelSuccess')
                        closeModal()
                    } else {
                        toastError('users.cancelInvitation.cancelFailed')
                    }

                },
                onError() {
                    setShowCancelInvitationModal(false)
                    toastError('users.cancelInvitation.cancelFailed')
                }
            }
        )
    }, [cancelInvitationResponse])


    return {
        getRoleKey,
        getDescription,
        tooltips,
        showResendInvitationModal,
        setShowResendInvitationModal,
        onConfirmResendInvitation,
        onConfirmCancelInvitation,
        showCancelInvitationModal,
        setShowCancelInvitationModal
    }
}

export {
    useReadOnlyInvitedUserInfoModal
}