import { RegisteredClient } from "@entities"
import { Cn } from "@helpers/unsorted/classNames"
import { Modal } from "@shared/unsorted/Modal/Modal"
import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useReadOnlyRegisteredUserInfoModal } from "./hook"
import { Icon } from "@shared/unsorted/Icon/Icon"
import { getWithDefault } from "@helpers/unsorted/stringExtra"
import { Label } from "@shared/unsorted/Label/Label"
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox"
import { Chip } from "@shared/unsorted/Chip/Chip"
import { isDefined } from "@helpers/core/typeGuards"
import { Button } from "@shared/unsorted/Button/Button"

const styles = {
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    header: Cn.c('flex justify-between items-start pb-5 pt-6 px-6 border-b border-b-default shrink-0'),
    titleContainer: Cn.c('flex flex-col space-y-4'),
    title: Cn.c('font-h4-bold text-emphasized'),
    iconContainer: Cn.c('p-3 w-fit border border-default rounded'),
    icon: Cn.c('w-6 h-6 text-icons-emphasized'),
    body: Cn.c('p-6 space-y-6 border-b border-default overflow-y-auto'),
    description: Cn.c('font-paragraph-small-regular text-subdued'),
    infoSection: Cn.c('space-y-0.5 w-full'),
    textInfo: Cn.c('font-paragraph-small-regular text-default'),
    roles: Cn.c('flex flex-wrap gap-2'),
    buttons: Cn.c('flex items-center justify-between space-x-3 p-6'),
    bodyContainer: Cn.c('flex flex-col min-h-0'),
    infoRow: Cn.c('flex justify-between space-x-4'),
    tooltip: Cn.c('w-fit'),
    modal: Cn.c('flex flex-col'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction
    client?: Omit<RegisteredClient, 'showWelcome' | 'status'>
}

const ReadOnlyRegisteredUserInfoModal: FunctionComponent<Props> = ({
    isOpen,
    closeModal,
    client
}) => {
    const { t } = useTranslation();

    const {
        tooltips,
        getDescription,
        getRoleKey
    } = useReadOnlyRegisteredUserInfoModal();

    return (
        <Modal close={closeModal} isOpen={isOpen} className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}><Icon name="user" className={styles.icon} /></div>
                    <p className={styles.title}>{t("users.show.title")}</p>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={closeModal} />
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.body}>
                    <p className={styles.description}> {getWithDefault(getDescription())} </p>
                    <div className={styles.infoRow}>
                        <div className={styles.infoSection}>
                            <Label label={"users.show.labels.lastName"} />
                            <p className={styles.textInfo}> {getWithDefault(client?.lastName)} </p>
                        </div>
                        <div className={styles.infoSection}>
                            <Label label={"users.show.labels.firstName"} />
                            <p className={styles.textInfo}> {getWithDefault(client?.firstName)} </p>
                        </div>
                    </div>
                    <div className={styles.infoRow}>
                        <div className={styles.infoSection}>
                            <Label label={"users.show.labels.lastNameKana"} />
                            <p className={styles.textInfo}> {getWithDefault(client?.lastNameKana)} </p>
                        </div>
                        <div className={styles.infoSection}>
                            <Label label={"users.show.labels.firstNameKana"} />
                            <p className={styles.textInfo}> {getWithDefault(client?.firstNameKana)} </p>
                        </div>
                    </div>
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.email"} />
                        <p className={styles.textInfo}> {getWithDefault(client?.email)} </p>
                    </div>
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.roles"} />
                        <div className={styles.roles}>
                            {client?.roles.map(({ name }) =>
                                <ToolTipBox
                                    tooltipContent={isDefined(tooltips[name]) ? tooltips[name] : ""}
                                    placement="bottom"
                                    toolTipClassName={styles.tooltip}
                                    key={name}
                                >
                                    <div>
                                        <Chip
                                            variant="outline"
                                            iconName="info"
                                            label={t(`users.role.${getRoleKey(name)}`)}
                                        />
                                    </div>
                                </ToolTipBox>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <Button size="md" variant="secondary" onClick={closeModal} isFull>
                    {t("global.close")}
                </Button>
            </div>
        </Modal >
    )
}

export {
    ReadOnlyRegisteredUserInfoModal
}