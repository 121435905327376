import { useToast } from '@helpers/hooks/unsorted/toastHook';
import { useEffect } from 'react';
import { InitialState } from './init';
import { useListPageTrackerContext } from '../../../../contexts/ListPageTrackerContext';
import { useLocation } from 'react-router-dom';

const usePreferences = (initialState: InitialState) => {
    const { error: toastError } = useToast();

    const [, setLastestListPageRoute] = useListPageTrackerContext()

    const location = useLocation()

    useEffect(() => {
        if (!initialState.isLoading && !initialState.result.isSuccess) {
            toastError('global.error');
        }
    }, [initialState])

    useEffect(() => {
        setLastestListPageRoute({
            PATH_NAME: location.pathname,
            search: location.search
        })
    }, [location])
};

export {
    usePreferences
};
