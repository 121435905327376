
import { Button } from '@shared/unsorted/Button/Button';
import { DefaultLayout } from '@shared/unsorted/DefaultLayout/DefaultLayout';
import { EmptyPageContainer } from '@shared/unsorted/EmptyStateContainer/EmptyPageContainer';
import { useTranslation } from 'react-i18next';
import { useListPageTrackerContext } from '../../../contexts/ListPageTrackerContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AnalyticsPage = () => {
    const { t } = useTranslation();

    const [, setLastestListPageRoute] = useListPageTrackerContext()

    const location = useLocation()

    // TODO: Temporarily declared this here since there's no point of creating a new hook file. Move to hook file if needed.
    const openEmail = () => {
        window.location.href = `mailto:bluum@reccoo.com`
    };

    useEffect(() => {
        setLastestListPageRoute({
            PATH_NAME: location.pathname,
            search: location.search
        })
    }, [location])

    return (
        <DefaultLayout>
            <EmptyPageContainer
                icon="emptyReport"
                description={t("comingSoon.description", { feature: t("analytics.title") })}
                subDescription="comingSoon.subDescription"
            >
                <Button size="md" onClick={openEmail}>{t("comingSoon.contactUs")}</Button>
            </EmptyPageContainer>
        </DefaultLayout>
    );
};

export {
    AnalyticsPage
};
