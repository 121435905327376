import { InvitedClient } from "@entities"
import { isDefined } from "@helpers/core/typeGuards";
import { Cn } from "@helpers/unsorted/classNames";
import { getWithDefault } from "@helpers/unsorted/stringExtra";
import { Button } from "@shared/unsorted/Button/Button";
import { Chip } from "@shared/unsorted/Chip/Chip";
import { Icon } from "@shared/unsorted/Icon/Icon";
import { Label } from "@shared/unsorted/Label/Label";
import { Modal } from "@shared/unsorted/Modal/Modal";
import { ToolTipBox } from "@shared/unsorted/ToolTipBox/ToolTipBox";
import { ModalProps } from "@typedefs/props"
import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useReadOnlyInvitedUserInfoModal } from "./hook";
import { ResendInvitationModal } from "./ResendInvitationModal/ResendInvitationModal";
import { CancelInvitationModal } from "./CancelInvitationModal/CancelInvitationModal";

const styles = {
    closeIcon: Cn.c('w-6 h-6 text-emphasized'),
    header: Cn.c('flex justify-between items-start pb-5 pt-6 px-6 border-b border-b-default shrink-0'),
    titleContainer: Cn.c('flex flex-col space-y-4'),
    title: Cn.c('font-h4-bold text-emphasized'),
    iconContainer: Cn.c('p-3 w-fit border border-default rounded'),
    icon: Cn.c('w-6 h-6 text-icons-emphasized'),
    body: Cn.c('p-6 space-y-6 border-b border-default overflow-y-auto'),
    description: Cn.c('font-paragraph-small-regular text-subdued'),
    infoSection: Cn.c('space-y-0.5 w-full'),
    textInfo: Cn.c('font-paragraph-small-regular text-default'),
    roles: Cn.c('flex flex-wrap gap-2'),
    buttons: Cn.c('flex items-center justify-between space-x-3 p-6'),
    rightButtons: Cn.c("flex items-center space-x-3"),
    bodyContainer: Cn.c('flex flex-col min-h-0'),
    infoRow: Cn.c('flex justify-between space-x-4'),
    tooltip: Cn.c('w-fit'),
    modal: Cn.c('flex flex-col'),
};

interface Props extends ModalProps {
    closeModal: VoidFunction
    client?: Omit<InvitedClient, 'status'>
}

const ReadOnlyInvitedUserInfoModal: FunctionComponent<Props> = ({
    client,
    isOpen,
    closeModal,
}) => {
    const { t } = useTranslation();

    const {
        tooltips,
        getDescription,
        getRoleKey,
        showResendInvitationModal,
        setShowResendInvitationModal,
        onConfirmResendInvitation,
        onConfirmCancelInvitation,
        showCancelInvitationModal,
        setShowCancelInvitationModal
    } = useReadOnlyInvitedUserInfoModal(closeModal, client?.email);

    const onClose = () => !showCancelInvitationModal && !showResendInvitationModal && closeModal()

    return (
        <Modal close={onClose} isOpen={isOpen} className={styles.modal}>
            <div className={styles.header}>
                <div className={styles.titleContainer}>
                    <div className={styles.iconContainer}><Icon name="user" className={styles.icon} /></div>
                    <p className={styles.title}>{t("users.show.title")}</p>
                </div>
                <Icon name="close" className={styles.closeIcon} onClick={onClose} />
            </div>
            <div className={styles.bodyContainer}>
                <div className={styles.body}>
                    <p className={styles.description}> {getWithDefault(getDescription())} </p>
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.email"} />
                        <p className={styles.textInfo}> {getWithDefault(client?.email)} </p>
                    </div>
                    <div className={styles.infoSection}>
                        <Label label={"users.show.labels.roles"} />
                        <div className={styles.roles}>
                            {client?.roles.map(({ name }) =>
                                <ToolTipBox
                                    tooltipContent={isDefined(tooltips[name]) ? tooltips[name] : ""}
                                    placement="bottom"
                                    toolTipClassName={styles.tooltip}
                                    key={name}
                                >
                                    <div>
                                        <Chip
                                            variant="outline"
                                            iconName="info"
                                            label={t(`users.role.${getRoleKey(name)}`)}
                                        />
                                    </div>
                                </ToolTipBox>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.buttons}>
                <Button size="md" variant="destructivePlain" onClick={() => setShowCancelInvitationModal(true)}>
                    {t("users.show.cancelInvitation")}
                </Button>
                <div className={styles.rightButtons}>
                    <Button size="md" variant="secondary" onClick={() => setShowResendInvitationModal(true)}>
                        {t("users.show.resendInvitation")}
                    </Button>
                    <Button size="md" variant="primaryFilled" onClick={onClose}>
                        {t("global.close")}
                    </Button>
                </div>
            </div>
            <ResendInvitationModal
                isOpen={showResendInvitationModal}
                onReject={() => setShowResendInvitationModal(false)}
                onConfirm={onConfirmResendInvitation}
            />
            <CancelInvitationModal
                isOpen={showCancelInvitationModal}
                onReject={() => setShowCancelInvitationModal(false)}
                onConfirm={onConfirmCancelInvitation}
                email={client?.email || ''}
            />
        </Modal >
    )
}

export {
    ReadOnlyInvitedUserInfoModal
}