import { Toaster } from '@shared/unsorted/Toaster/Toaster';
import { StrictMode, useState } from 'react';

import { App } from './App';
import { GraphqlProvider } from '../../../contexts/GraphqlContext';
import { ClientProvider } from '../../../contexts/ClientContext';
import { ListPageTrackerProvider } from '../../../contexts/ListPageTrackerContext';
import { useRoot } from './rootHook';
import { Loading } from '@shared/core/Loading/Loading';
import { Route } from '@routes/hook';

const Root = () => {
    const { clientState, graphqlClient, loadingProgress, showLoadingScreen } = useRoot()
    const [lastestListPageRoute, setLastestListPageRoute] = useState<Route | undefined>(undefined)

    return (
        <ListPageTrackerProvider value={[lastestListPageRoute, setLastestListPageRoute]}>
            <GraphqlProvider client={graphqlClient}>
                <ClientProvider value={clientState}>
                    <StrictMode>
                        {showLoadingScreen && <Loading progress={loadingProgress} />}
                        <App />
                        <Toaster />
                    </StrictMode>
                </ClientProvider>
            </GraphqlProvider>
        </ListPageTrackerProvider>
    );
};

export {
    Root,
};
